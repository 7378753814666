var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.blog._id
    ? _c("div", { staticClass: "blog-posts pt-3 m-auto" }, [
        _c("div", { staticClass: "post-details-wrap" }, [
          _c("div", [
            _c("div", { staticClass: "hero-details" }, [
              _c("h1", [_vm._v(_vm._s(_vm.title))]),
            ]),
            _c(
              "div",
              { staticClass: "post-details" },
              [
                _c(
                  "div",
                  { staticClass: "post-author" },
                  [
                    _c("BIconPersonCircle", { attrs: { scale: "1" } }),
                    _c("ProfileLink", {
                      attrs: { profile: _vm.blog.info.author },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "post-time" },
                  [
                    _c("BIconCalendarRange", { attrs: { scale: "1" } }),
                    _c("Date", {
                      attrs: {
                        date: _vm.blog.info.date,
                        format: "dynamicDate",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "post-comments" },
                  [
                    _c("BIconChatTextFill", { attrs: { scale: "1" } }),
                    _c("b-link", { on: { click: _vm.toComments } }, [
                      _vm._v(" " + _vm._s(_vm.blog.comments.count) + " "),
                    ]),
                  ],
                  1
                ),
                _vm.canEdit
                  ? _c(
                      "div",
                      { staticClass: "post-edit" },
                      [
                        _c("BIconPencilSquare", { attrs: { scale: "1" } }),
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "update-blog",
                                params: { id: _vm.blog._id },
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("generic.edit-button")) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isAdmin
                  ? _c(
                      "div",
                      { staticClass: "post-editorial" },
                      [
                        !_vm.hidden
                          ? _c(
                              "b-link",
                              { on: { click: _vm.toggleHidden } },
                              [
                                _c("BIconShieldPlus", {
                                  attrs: { scale: "1" },
                                }),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("blog.hidden.mark")) + " "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.hidden
                          ? _c(
                              "b-link",
                              { on: { click: _vm.toggleHidden } },
                              [
                                _c("BIconShieldMinus", {
                                  attrs: { scale: "1" },
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("blog.hidden.unmark")) +
                                    " "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.canDelete
                  ? _c(
                      "div",
                      { staticClass: "post-delete" },
                      [
                        _c(
                          "b-link",
                          {
                            directives: [
                              {
                                name: "b-modal",
                                rawName: "v-b-modal.confirm",
                                modifiers: { confirm: true },
                              },
                            ],
                          },
                          [
                            _c("BIconXCircle", { attrs: { scale: "1" } }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("generic.delete-button")) +
                                " "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "b-modal",
                  {
                    attrs: {
                      id: "confirm",
                      title: _vm.$t("generic.confirm-title"),
                      "hide-footer": "",
                    },
                  },
                  [
                    _c("p", { staticClass: "my-4" }, [
                      _vm._v(_vm._s(_vm.$t("blog.confirm-delete"))),
                    ]),
                    _c(
                      "b-button",
                      {
                        staticClass: "mt-3 mr-2",
                        on: {
                          click: function ($event) {
                            return _vm.$bvModal.hide("confirm")
                          },
                        },
                      },
                      [_vm._v(_vm._s(this.$t("generic.cancel-button")))]
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "mt-3",
                        attrs: { variant: "danger" },
                        on: {
                          click: function ($event) {
                            _vm.deleteBlog()
                            _vm.$bvModal.hide("confirm")
                          },
                        },
                      },
                      [_vm._v(_vm._s(this.$t("generic.ok-button")))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "errors" },
              [
                _c(
                  "b-alert",
                  {
                    attrs: {
                      variant: "danger",
                      dismissible: "",
                      show: _vm.error !== undefined,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.error) + " ")]
                ),
              ],
              1
            ),
            _c("div", {
              staticClass: "post-content p3",
              domProps: { innerHTML: _vm._s(this.blog.data.content) },
            }),
            _c(
              "div",
              { staticClass: "content-wrap" },
              [
                _c("ShareLink", { attrs: { item: _vm.blog } }),
                _c("Comments", { attrs: { itemId: _vm.blog._id } }),
              ],
              1
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }