var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showPrefs
    ? _c(
        "div",
        { staticClass: "cookies-box-wrap" },
        [
          _c("div", { staticClass: "cookies-box-inner" }, [
            _c(
              "p",
              [
                _c("strong", [_vm._v("Povolíte nám shromažďovat cookies?")]),
                _c(
                  "b-button",
                  {
                    staticClass: "closebtn",
                    attrs: { size: "sm" },
                    on: { click: _vm.close },
                  },
                  [_c("BIconXCircle", { attrs: { scale: "1.5" } })],
                  1
                ),
              ],
              1
            ),
            _c(
              "p",
              [
                _vm._v(
                  " Hrajeme fér. Potřebujeme vědět, kdo naše stránky navštěvuje, ať už z pohledu analýzy návštěvnosti či cílení reklamy. Váš souhlas nám pomůže. Děkujeme."
                ),
                _c("br"),
                _c("router-link", { attrs: { to: "/o/soukromi" } }, [
                  _vm._v("Zpracování osobních údajů"),
                ]),
                _vm._v(", "),
                _c("router-link", { attrs: { to: "/o/podminky" } }, [
                  _vm._v("Podmínky užívání"),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "action-btns" },
              [
                _c(
                  "b-button",
                  {
                    directives: [{ name: "b-modal", rawName: "v-b-modal" }],
                    staticClass: "squared",
                    on: {
                      click: function ($event) {
                        return _vm.confirmAll()
                      },
                    },
                  },
                  [_vm._v("Jasně, všechny")]
                ),
                _c(
                  "b-button",
                  {
                    directives: [{ name: "b-modal", rawName: "v-b-modal" }],
                    staticClass: "bordered",
                    on: {
                      click: function ($event) {
                        return _vm.denyAll()
                      },
                    },
                  },
                  [_vm._v("Jen nutné")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "bordered",
                    attrs: { variant: "squared" },
                    on: {
                      click: function ($event) {
                        return _vm.$bvModal.show("modal-scrollable")
                      },
                    },
                  },
                  [_vm._v("Vytunit")]
                ),
              ],
              1
            ),
          ]),
          _c(
            "b-modal",
            {
              attrs: { id: "modal-scrollable", scrollable: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modal-header",
                    fn: function (ref) {
                      var close = ref.close
                      return [
                        _c("div", { staticClass: "title-tag" }, [
                          _c("img", {
                            staticClass: "brand",
                            attrs: {
                              src: "/images/icons/logo-black.png",
                              alt: _vm.$t("app.logo-alt"),
                            },
                          }),
                          _c("h2", [_vm._v(_vm._s(_vm.$t("app.name")))]),
                        ]),
                        _c(
                          "b-button",
                          {
                            staticClass: "closebtn",
                            attrs: { size: "sm" },
                            on: {
                              click: function ($event) {
                                return close()
                              },
                            },
                          },
                          [_c("BIconXCircle", { attrs: { scale: "1.5" } })],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "modal-footer",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "action-btns" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "squared text-white",
                                attrs: { size: "lg", variant: "squared" },
                                on: {
                                  click: function ($event) {
                                    return _vm.savePreferences()
                                  },
                                },
                              },
                              [_vm._v(" Uložit ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2004272198
              ),
            },
            [
              [
                _c("div", { staticClass: "content-inside" }, [
                  _c("h5", [_vm._v("Mezi námi řidiči,")]),
                  _c("p", [
                    _vm._v(
                      " nechceme potíže s úřady, takže pojďme na papírování. Technologie cookies slouží pro ukládání dat do prohlížeče. Zde si můžete vybrat, kterou kategorii cookies povolíte. Nám pomůže, když povolíte všechny. "
                    ),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "green text-white mb-4 mt-2",
                      on: {
                        click: function ($event) {
                          return _vm.confirmAll()
                        },
                      },
                    },
                    [_vm._v("Povolit vše")]
                  ),
                  _c(
                    "p",
                    [
                      _vm._v(" Vše je podrobně vysvětleno v dokumentu "),
                      _c("router-link", { attrs: { to: "/o/soukromi" } }, [
                        _vm._v("Zásady zpracování osobních údajů"),
                      ]),
                      _vm._v(". Také byste měli znát "),
                      _c("router-link", { attrs: { to: "/o/podminky" } }, [
                        _vm._v("Podmínky užívání"),
                      ]),
                      _vm._v(". "),
                    ],
                    1
                  ),
                  _c("h5", [_vm._v("Správa cookies")]),
                  _c(
                    "div",
                    { staticClass: "inner-header my-3" },
                    [
                      _c("h6", [_vm._v("Funkční cookies")]),
                      _c("b-form-checkbox", {
                        attrs: { switch: "", size: "lg" },
                        model: {
                          value: _vm.personalization,
                          callback: function ($$v) {
                            _vm.personalization = $$v
                          },
                          expression: "personalization",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("p", [
                    _vm._v(
                      " Používají se pro ukládání nastavení. Web bude fungovat, ale třeba zapomene, co jste nastavili nebo viděli naposledy. "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "inner-header my-3" },
                    [
                      _c("h6", [_vm._v("Analytické cookies")]),
                      _c("b-form-checkbox", {
                        attrs: { switch: "", size: "lg" },
                        model: {
                          value: _vm.analytics,
                          callback: function ($$v) {
                            _vm.analytics = $$v
                          },
                          expression: "analytics",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("p", [
                    _vm._v(
                      " Používáme Google Analytics pro měření návštěvnosti. Bez cookies nepoznáme, zda jste tu podruhé nebo je to někdo jiný. "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "inner-header my-3" },
                    [
                      _c("h6", [_vm._v("Reklamní cookies")]),
                      _c("b-form-checkbox", {
                        attrs: { switch: "", size: "lg" },
                        model: {
                          value: _vm.ad,
                          callback: function ($$v) {
                            _vm.ad = $$v
                          },
                          expression: "ad",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("p", [
                    _vm._v(
                      " Pokud inzerenti nebudou vědět, kolik lidí jejich reklamu vidělo, jak často a zda na ni kliknuli, nebudou reklamu kupovat. Důsledky pro web si domyslete sami. "
                    ),
                  ]),
                  _c("div", { staticClass: "inner-header my-3" }, [
                    _c("h6", [_vm._v("Nezbytné cookies")]),
                    _c("b", { staticClass: "active" }, [
                      _vm._v("Vždy aktivní"),
                    ]),
                  ]),
                  _c("p", [
                    _vm._v(
                      " Tohle jsou cookies používané například k zapamatování přihlášení. "
                    ),
                  ]),
                ]),
              ],
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }