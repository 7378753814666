var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-5 border centerbox" },
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("div", { staticClass: "head-area" }, [
              _c("h2", [
                _vm._v(_vm._s(_vm.$t("sign-in.reset-password-heading"))),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("p", [
              _vm._v(_vm._s(_vm.$t("sign-in.reset-password-description"))),
            ]),
          ]),
        ],
        1
      ),
      _c("ValidationObserver", {
        ref: "form",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var passes = ref.passes
              var invalid = ref.invalid
              return [
                _c(
                  "b-form",
                  {
                    attrs: { action: "placeholder", method: "post" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return passes(_vm.resetPassword)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "field-area" },
                      [
                        _c("TextInput", {
                          attrs: {
                            label: _vm.$t("profile.email"),
                            name: "email",
                            type: "email",
                            disabled: "",
                          },
                          model: {
                            value: _vm.email,
                            callback: function ($$v) {
                              _vm.email = $$v
                            },
                            expression: "email",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "field-area" },
                      [
                        _c("TextInput", {
                          attrs: {
                            rules: "required|min:6",
                            label: _vm.$t("sign-in.new-password"),
                            name: "new-password",
                            type: "password",
                          },
                          model: {
                            value: _vm.newPassword,
                            callback: function ($$v) {
                              _vm.newPassword = $$v
                            },
                            expression: "newPassword",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.error
                      ? _c("div", { staticClass: "errormsg" }, [
                          _c("strong", { staticClass: "text-danger" }, [
                            _vm._v(" " + _vm._s(_vm.error) + " "),
                          ]),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "m-auto" },
                      [
                        _c("Button", {
                          staticClass: "w-100 green",
                          attrs: {
                            type: "submit",
                            disabled: invalid,
                            value: _vm.$t("sign-in.change-password-button"),
                          },
                          on: { clicked: _vm.resetPassword },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }