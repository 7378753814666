var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "normal-poll pt-1" }, [
    _c(
      "div",
      { staticClass: "text-center normal-poll-inside" },
      [
        _c("h4", { staticClass: "pb-2 mb-3" }, [
          _vm._v(_vm._s(_vm.$t("poll.your-opinion"))),
        ]),
        _c(
          "b-button",
          {
            attrs: { variant: "no-problem-btn" },
            on: {
              click: function ($event) {
                return _vm.voted("neutral")
              },
            },
          },
          [
            _c("img", { attrs: { src: "/images/icons/happy.svg", alt: "" } }),
            _vm._v(" " + _vm._s(_vm.$t("poll.choices.neutral")) + " "),
          ]
        ),
        _c(
          "b-button",
          {
            attrs: { variant: "traval-trouble-btn" },
            on: {
              click: function ($event) {
                return _vm.voted("trivial")
              },
            },
          },
          [
            _c("img", { attrs: { src: "/images/icons/ok.svg", alt: "" } }),
            _vm._v(" " + _vm._s(_vm.$t("poll.choices.trivial")) + " "),
          ]
        ),
        _c(
          "b-button",
          {
            attrs: { variant: "dislike-btn" },
            on: {
              click: function ($event) {
                return _vm.voted("dislike")
              },
            },
          },
          [
            _c("img", { attrs: { src: "/images/icons/dislike.svg", alt: "" } }),
            _vm._v(" " + _vm._s(_vm.$t("poll.choices.dislike")) + " "),
          ]
        ),
        _c(
          "b-button",
          {
            attrs: { variant: "danger" },
            on: {
              click: function ($event) {
                return _vm.voted("hate")
              },
            },
          },
          [
            _c("img", { attrs: { src: "/images/icons/angry.svg", alt: "" } }),
            _vm._v(" " + _vm._s(_vm.$t("poll.choices.hate")) + " "),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }