var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "m-auto item-div item-hover" },
    [
      _c(
        "router-link",
        { attrs: { to: _vm.link } },
        [_c("b-img", { attrs: { src: _vm.item.info.picture, alt: "" } })],
        1
      ),
      _c("div", { staticClass: "picture-actions" }, [
        _vm.item.type === "article"
          ? _c("span", { staticClass: "picture-overlay overlay-left" }, [
              _vm._v(" " + _vm._s(_vm.$t("generic.content.article")) + " "),
            ])
          : _vm._e(),
        _vm.item.type === "blog"
          ? _c("span", { staticClass: "picture-overlay overlay-left" }, [
              _vm._v(" " + _vm._s(_vm.$t("generic.content.blog")) + " "),
            ])
          : _vm._e(),
        _vm.item.type === "poll"
          ? _c("span", { staticClass: "picture-overlay overlay-left" }, [
              _vm._v(" " + _vm._s(_vm.$t("generic.content.poll")) + " "),
            ])
          : _vm._e(),
        _c(
          "span",
          { staticClass: "picture-overlay overlay-right" },
          [
            _c("Date", {
              attrs: { date: _vm.item.info.date, format: "dynamicDate" },
            }),
          ],
          1
        ),
      ]),
      _c(
        "h4",
        [
          _c("router-link", { attrs: { to: _vm.link } }, [
            _vm._v(" " + _vm._s(_vm.item.info.caption) + " "),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "py-2 px-2 item-footer" }, [
        _c(
          "div",
          { staticClass: "bottom-links" },
          [
            _vm.showAuthor
              ? [
                  _c(
                    "span",
                    [
                      _c("BIconPersonCircle"),
                      _vm._v(" "),
                      _c("ProfileLink", {
                        attrs: { profile: _vm.item.info.author },
                      }),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
            _vm.item.type === "poll"
              ? [
                  _vm._v(
                    _vm._s(_vm.$t("poll.votes")) +
                      ": " +
                      _vm._s(_vm.item.votes_count)
                  ),
                ]
              : _vm._e(),
            _vm.hasDiscussion
              ? [
                  _c(
                    "span",
                    [
                      _c(
                        "router-link",
                        { attrs: { to: _vm.commentLink } },
                        [
                          _c("BIconChatTextFill"),
                          _vm._v(" " + _vm._s(_vm.item.comments.count) + " "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }