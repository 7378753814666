var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "centerbox" }, [
    _c(
      "div",
      { staticClass: "field-area" },
      [
        _c(
          "b-form-group",
          {
            staticClass: "mb-0",
            attrs: {
              label: _vm.$t("profile.about-me-label"),
              "label-for": "textarea-formatter",
              description: _vm.$t("profile.about-me-description"),
            },
          },
          [
            _c("b-form-textarea", {
              attrs: {
                id: "textarea-formatter",
                placeholder: _vm.$t("profile.about-me-hint"),
                rows: "3",
                maxlength: "2000",
              },
              model: {
                value: _vm.about,
                callback: function ($$v) {
                  _vm.about = $$v
                },
                expression: "about",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "field-area" }, [
      _c("div", [
        _c("label", { staticClass: "d-label" }, [
          _vm._v(_vm._s(_vm.$t("edit-profile.urls"))),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "pb-2" },
        [
          _c("b-form-input", {
            attrs: { placeholder: _vm.$t("edit-profile.enter-url") },
            model: {
              value: _vm.urls[0],
              callback: function ($$v) {
                _vm.$set(_vm.urls, 0, $$v)
              },
              expression: "urls[0]",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pb-2" },
        [
          _c("b-form-input", {
            attrs: { placeholder: _vm.$t("edit-profile.enter-url") },
            model: {
              value: _vm.urls[1],
              callback: function ($$v) {
                _vm.$set(_vm.urls, 1, $$v)
              },
              expression: "urls[1]",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pb-2" },
        [
          _c("b-form-input", {
            attrs: { placeholder: _vm.$t("edit-profile.enter-url") },
            model: {
              value: _vm.urls[2],
              callback: function ($$v) {
                _vm.$set(_vm.urls, 2, $$v)
              },
              expression: "urls[2]",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "field-area" }, [
      _c("label", { staticClass: "d-label", attrs: { for: "share-profile" } }, [
        _vm._v(_vm._s(_vm.$t("profile.share-profile"))),
      ]),
      _c("div", { staticClass: "radio-boxes" }, [
        _c(
          "div",
          { staticClass: "public-box" },
          [
            _c("Radio", {
              attrs: {
                identifier: "public",
                label: _vm.$t("profile.public"),
                name: "share-profile",
              },
              model: {
                value: _vm.share,
                callback: function ($$v) {
                  _vm.share = $$v
                },
                expression: "share",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "private-box" },
          [
            _c("Radio", {
              attrs: {
                identifier: "private",
                label: _vm.$t("profile.private"),
                name: "share-profile",
              },
              model: {
                value: _vm.share,
                callback: function ($$v) {
                  _vm.share = $$v
                },
                expression: "share",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "field-area" },
      [
        _c("Datepicker", {
          attrs: {
            label: _vm.$t("profile.driving-since-label"),
            format: "yyyy",
            minimumView: "year",
            type: "number",
            "disabled-dates": _vm.drivingDateScope,
            typeable: true,
            name: "driving-since",
          },
          model: {
            value: _vm.drivingSince,
            callback: function ($$v) {
              _vm.drivingSince = $$v
            },
            expression: "drivingSince",
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "field-area" }, [
      _c("div", [
        _c("label", { staticClass: "d-label", attrs: { for: "vehicle" } }, [
          _vm._v(_vm._s(_vm.$t("profile.vehicles-label"))),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("Checkbox", {
            staticClass: "pl-3",
            attrs: {
              label: _vm.$t("profile.vehicles.bike"),
              name: "vehicle",
              identifier: "bike",
            },
            model: {
              value: _vm.bike,
              callback: function ($$v) {
                _vm.bike = $$v
              },
              expression: "bike",
            },
          }),
          _c("Checkbox", {
            staticClass: "pl-3",
            attrs: {
              label: _vm.$t("profile.vehicles.car"),
              name: "vehicle",
              identifier: "car",
            },
            model: {
              value: _vm.car,
              callback: function ($$v) {
                _vm.car = $$v
              },
              expression: "car",
            },
          }),
          _c("Checkbox", {
            staticClass: "pl-3",
            attrs: {
              label: _vm.$t("profile.vehicles.bus"),
              name: "vehicle",
              identifier: "bus",
            },
            model: {
              value: _vm.bus,
              callback: function ($$v) {
                _vm.bus = $$v
              },
              expression: "bus",
            },
          }),
          _c("Checkbox", {
            staticClass: "pl-3",
            attrs: {
              label: _vm.$t("profile.vehicles.van"),
              name: "vehicle",
              identifier: "van",
            },
            model: {
              value: _vm.van,
              callback: function ($$v) {
                _vm.van = $$v
              },
              expression: "van",
            },
          }),
          _c("Checkbox", {
            staticClass: "pl-3",
            attrs: {
              label: _vm.$t("profile.vehicles.truck"),
              name: "vehicle",
              identifier: "truck",
            },
            model: {
              value: _vm.truck,
              callback: function ($$v) {
                _vm.truck = $$v
              },
              expression: "truck",
            },
          }),
          _c("Checkbox", {
            staticClass: "pl-3",
            attrs: {
              label: _vm.$t("profile.vehicles.tramway"),
              name: "vehicle",
              identifier: "tramway",
            },
            model: {
              value: _vm.tramway,
              callback: function ($$v) {
                _vm.tramway = $$v
              },
              expression: "tramway",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "field-area" }, [
      _c("div", [
        _c("label", { staticClass: "d-label", attrs: { for: "sex" } }, [
          _vm._v(_vm._s(_vm.$t("profile.sex"))),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("Radio", {
            staticClass: "pl-3",
            attrs: {
              label: _vm.$t("profile.sexes.man"),
              name: "sex",
              identifier: "man",
            },
            model: {
              value: _vm.sex,
              callback: function ($$v) {
                _vm.sex = $$v
              },
              expression: "sex",
            },
          }),
          _c("Radio", {
            staticClass: "pl-3",
            attrs: {
              label: _vm.$t("profile.sexes.woman"),
              name: "sex",
              identifier: "woman",
            },
            model: {
              value: _vm.sex,
              callback: function ($$v) {
                _vm.sex = $$v
              },
              expression: "sex",
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "field-area" },
      [
        _c("Datepicker", {
          attrs: {
            label: _vm.$t("profile.born"),
            format: "yyyy",
            type: "number",
            minimumView: "year",
            "disabled-dates": _vm.bornDateScope,
            typeable: true,
            name: "born",
          },
          model: {
            value: _vm.bornInYear,
            callback: function ($$v) {
              _vm.bornInYear = $$v
            },
            expression: "bornInYear",
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "field-area" }, [
      _c("div", [
        _c("label", { staticClass: "d-label", attrs: { for: "region" } }, [
          _vm._v(_vm._s(_vm.$t("profile.region"))),
        ]),
      ]),
      _c("div", { staticClass: "row pl-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.region,
                expression: "region",
              },
            ],
            staticClass: "form-control",
            attrs: { id: "region" },
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.region = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
            },
          },
          [
            _c("option", { attrs: { value: "" } }, [
              _vm._v(_vm._s(_vm.$t("sign-up.region-options"))),
            ]),
            _c("option", { attrs: { value: "PRG" } }, [
              _vm._v(_vm._s(_vm.$t("profile.regions.PRG"))),
            ]),
            _c("option", { attrs: { value: "SC" } }, [
              _vm._v(_vm._s(_vm.$t("profile.regions.SC"))),
            ]),
            _c("option", { attrs: { value: "JC" } }, [
              _vm._v(_vm._s(_vm.$t("profile.regions.JC"))),
            ]),
            _c("option", { attrs: { value: "PLS" } }, [
              _vm._v(_vm._s(_vm.$t("profile.regions.PLS"))),
            ]),
            _c("option", { attrs: { value: "KV" } }, [
              _vm._v(_vm._s(_vm.$t("profile.regions.KV"))),
            ]),
            _c("option", { attrs: { value: "UST" } }, [
              _vm._v(_vm._s(_vm.$t("profile.regions.UST"))),
            ]),
            _c("option", { attrs: { value: "LBR" } }, [
              _vm._v(_vm._s(_vm.$t("profile.regions.LBR"))),
            ]),
            _c("option", { attrs: { value: "KH" } }, [
              _vm._v(_vm._s(_vm.$t("profile.regions.KH"))),
            ]),
            _c("option", { attrs: { value: "PRD" } }, [
              _vm._v(_vm._s(_vm.$t("profile.regions.PRD"))),
            ]),
            _c("option", { attrs: { value: "VSC" } }, [
              _vm._v(_vm._s(_vm.$t("profile.regions.VSC"))),
            ]),
            _c("option", { attrs: { value: "JM" } }, [
              _vm._v(_vm._s(_vm.$t("profile.regions.JM"))),
            ]),
            _c("option", { attrs: { value: "OLM" } }, [
              _vm._v(_vm._s(_vm.$t("profile.regions.OLM"))),
            ]),
            _c("option", { attrs: { value: "ZLN" } }, [
              _vm._v(_vm._s(_vm.$t("profile.regions.ZLN"))),
            ]),
            _c("option", { attrs: { value: "MS" } }, [
              _vm._v(_vm._s(_vm.$t("profile.regions.MS"))),
            ]),
          ]
        ),
      ]),
    ]),
    _c("div", { staticClass: "field-area" }, [
      _c("div", [
        _c("label", { staticClass: "d-label", attrs: { for: "education" } }, [
          _vm._v(_vm._s(_vm.$t("profile.education"))),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("Radio", {
            staticClass: "pl-3",
            attrs: {
              label: _vm.$t("profile.educations.primary"),
              name: "education",
              identifier: "primary",
            },
            model: {
              value: _vm.education,
              callback: function ($$v) {
                _vm.education = $$v
              },
              expression: "education",
            },
          }),
          _c("Radio", {
            staticClass: "pl-3",
            attrs: {
              label: _vm.$t("profile.educations.secondary"),
              name: "education",
              identifier: "secondary",
            },
            model: {
              value: _vm.education,
              callback: function ($$v) {
                _vm.education = $$v
              },
              expression: "education",
            },
          }),
          _c("Radio", {
            staticClass: "pl-3",
            attrs: {
              label: _vm.$t("profile.educations.university"),
              name: "education",
              identifier: "university",
            },
            model: {
              value: _vm.education,
              callback: function ($$v) {
                _vm.education = $$v
              },
              expression: "education",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }