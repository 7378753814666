var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "p",
      [
        _vm._v(_vm._s(_vm.$t("infoBox.reducingAccidents.para")) + " "),
        _c("router-link", { attrs: { to: "#" } }, [
          _vm._v(_vm._s(_vm.$t("infoBox.readMore"))),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }