var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [{ name: "blur-event", rawName: "v-blur-event" }],
      staticClass: "editor",
    },
    [
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "b-alert",
                {
                  attrs: {
                    show: _vm.showDismissibleAlert,
                    dismissible: "",
                    variant: "danger",
                  },
                  on: { dismissed: _vm.dismissError },
                },
                [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
              ),
            ],
            1
          ),
          _vm.editor
            ? _c("div", { staticClass: "menubar" }, [
                _c(
                  "button",
                  {
                    staticClass: "menubar__button",
                    attrs: { title: _vm.$t("editor.menu.undo") },
                    on: {
                      click: function ($event) {
                        _vm.editor.chain().focus().undo().run()
                      },
                    },
                  },
                  [_c("icon", { attrs: { name: "undo" } })],
                  1
                ),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button",
                    attrs: { title: _vm.$t("editor.menu.redo") },
                    on: {
                      click: function ($event) {
                        _vm.editor.chain().focus().redo().run()
                      },
                    },
                  },
                  [_c("icon", { attrs: { name: "redo" } })],
                  1
                ),
                !_vm.editor.isActive("link")
                  ? _c(
                      "button",
                      {
                        staticClass: "menubar__button",
                        attrs: { title: _vm.$t("editor.menu.link") },
                        on: { click: _vm.showLinkMenu },
                      },
                      [_c("icon", { attrs: { name: "link" } })],
                      1
                    )
                  : _vm._e(),
                _vm.editor.isActive("link")
                  ? _c(
                      "button",
                      {
                        staticClass: "is-active menubar__button",
                        attrs: { title: _vm.$t("editor.menu.unlink") },
                        on: {
                          click: function ($event) {
                            _vm.editor.chain().focus().unsetLink().run()
                          },
                        },
                      },
                      [_c("icon", { attrs: { name: "remove" } })],
                      1
                    )
                  : _vm._e(),
                _vm.editor.isActive("link")
                  ? _c(
                      "button",
                      {
                        staticClass: "is-active menubar__button",
                        attrs: { title: _vm.$t("editor.menu.link-edit") },
                        on: { click: _vm.showLinkMenu },
                      },
                      [_c("icon", { attrs: { name: "link" } })],
                      1
                    )
                  : _vm._e(),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button",
                    class: { "is-active": _vm.editor.isActive("bold") },
                    attrs: { title: _vm.$t("editor.menu.bold") },
                    on: {
                      click: function ($event) {
                        _vm.editor.chain().focus().toggleBold().run()
                      },
                    },
                  },
                  [_c("icon", { attrs: { name: "bold" } })],
                  1
                ),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button",
                    class: { "is-active": _vm.editor.isActive("italic") },
                    attrs: { title: _vm.$t("editor.menu.italic") },
                    on: {
                      click: function ($event) {
                        _vm.editor.chain().focus().toggleItalic().run()
                      },
                    },
                  },
                  [_c("icon", { attrs: { name: "italic" } })],
                  1
                ),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button",
                    class: { "is-active": _vm.editor.isActive("strike") },
                    attrs: { title: _vm.$t("editor.menu.strike") },
                    on: {
                      click: function ($event) {
                        _vm.editor.chain().focus().toggleStrike().run()
                      },
                    },
                  },
                  [_c("icon", { attrs: { name: "strike" } })],
                  1
                ),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button",
                    class: { "is-active": _vm.editor.isActive("underline") },
                    attrs: { title: _vm.$t("editor.menu.underline") },
                    on: {
                      click: function ($event) {
                        _vm.editor.chain().focus().toggleUnderline().run()
                      },
                    },
                  },
                  [_c("icon", { attrs: { name: "underline" } })],
                  1
                ),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button",
                    class: {
                      "is-active": _vm.editor.isActive("heading", { level: 1 }),
                    },
                    attrs: { title: _vm.$t("editor.menu.h1") },
                    on: {
                      click: function ($event) {
                        _vm.editor
                          .chain()
                          .focus()
                          .toggleHeading({ level: 1 })
                          .run()
                      },
                    },
                  },
                  [_vm._v(" H1 ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button",
                    class: {
                      "is-active": _vm.editor.isActive("heading", { level: 2 }),
                    },
                    attrs: { title: _vm.$t("editor.menu.h2") },
                    on: {
                      click: function ($event) {
                        _vm.editor
                          .chain()
                          .focus()
                          .toggleHeading({ level: 2 })
                          .run()
                      },
                    },
                  },
                  [_vm._v(" H2 ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button",
                    class: {
                      "is-active": _vm.editor.isActive("heading", { level: 3 }),
                    },
                    attrs: { title: _vm.$t("editor.menu.h3") },
                    on: {
                      click: function ($event) {
                        _vm.editor
                          .chain()
                          .focus()
                          .toggleHeading({ level: 3 })
                          .run()
                      },
                    },
                  },
                  [_vm._v(" H3 ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button",
                    class: { "is-active": _vm.editor.isActive("bulletList") },
                    attrs: { title: _vm.$t("editor.menu.ul") },
                    on: {
                      click: function ($event) {
                        _vm.editor.chain().focus().toggleBulletList().run()
                      },
                    },
                  },
                  [_c("icon", { attrs: { name: "ul" } })],
                  1
                ),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button",
                    class: { "is-active": _vm.editor.isActive("orderedList") },
                    attrs: { title: _vm.$t("editor.menu.ol") },
                    on: {
                      click: function ($event) {
                        _vm.editor.chain().focus().toggleOrderedList().run()
                      },
                    },
                  },
                  [_c("icon", { attrs: { name: "ol" } })],
                  1
                ),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button",
                    class: { "is-active": _vm.editor.isActive("codeBlock") },
                    attrs: { title: _vm.$t("editor.menu.quote") },
                    on: {
                      click: function ($event) {
                        _vm.editor.chain().focus().toggleCodeBlock().run()
                      },
                    },
                  },
                  [_c("icon", { attrs: { name: "quote" } })],
                  1
                ),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button",
                    attrs: { title: _vm.$t("editor.menu.hr") },
                    on: {
                      click: function ($event) {
                        _vm.editor.chain().focus().setHorizontalRule().run()
                      },
                    },
                  },
                  [_c("icon", { attrs: { name: "hr" } })],
                  1
                ),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button",
                    attrs: { title: _vm.$t("editor.menu.image") },
                    on: { click: _vm.showImageModal },
                  },
                  [_c("Icon", { attrs: { name: "image" } })],
                  1
                ),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button",
                    attrs: { title: _vm.$t("editor.menu.media") },
                    on: { click: _vm.addIframe },
                  },
                  [_c("icon", { attrs: { name: "video" } })],
                  1
                ),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button",
                    attrs: { title: _vm.$t("editor.menu.table-add") },
                    on: {
                      click: function ($event) {
                        _vm.editor
                          .chain()
                          .focus()
                          .insertTable({
                            rows: 2,
                            cols: 2,
                            withHeaderRow: false,
                          })
                          .run()
                      },
                    },
                  },
                  [_c("icon", { attrs: { name: "table" } })],
                  1
                ),
                _vm.editor.can().addColumnBefore()
                  ? _c("span", [
                      _c(
                        "button",
                        {
                          staticClass: "menubar__button",
                          attrs: {
                            title: _vm.$t("editor.menu.table-delete"),
                            disabled: !_vm.editor.can().deleteTable(),
                          },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().deleteTable().run()
                            },
                          },
                        },
                        [_c("icon", { attrs: { name: "delete_table" } })],
                        1
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "menubar__button",
                          attrs: {
                            title: _vm.$t("editor.menu.column-add-before"),
                            disabled: !_vm.editor.can().addColumnBefore(),
                          },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().addColumnBefore().run()
                            },
                          },
                        },
                        [_c("icon", { attrs: { name: "add_col_before" } })],
                        1
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "menubar__button",
                          attrs: {
                            title: _vm.$t("editor.menu.column-add-after"),
                            disabled: !_vm.editor.can().addColumnAfter(),
                          },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().addColumnAfter().run()
                            },
                          },
                        },
                        [_c("icon", { attrs: { name: "add_col_after" } })],
                        1
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "menubar__button",
                          attrs: {
                            title: _vm.$t("editor.menu.column-delete"),
                            disabled: !_vm.editor.can().deleteColumn(),
                          },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().deleteColumn().run()
                            },
                          },
                        },
                        [_c("icon", { attrs: { name: "delete_col" } })],
                        1
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "menubar__button",
                          attrs: {
                            title: _vm.$t("editor.menu.row-add-before"),
                            disabled: !_vm.editor.can().addRowBefore(),
                          },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().addRowBefore().run()
                            },
                          },
                        },
                        [_c("icon", { attrs: { name: "add_row_before" } })],
                        1
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "menubar__button",
                          attrs: {
                            title: _vm.$t("editor.menu.row-add-after"),
                            disabled: !_vm.editor.can().addRowAfter(),
                          },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().addRowAfter().run()
                            },
                          },
                        },
                        [_c("icon", { attrs: { name: "add_row_after" } })],
                        1
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "menubar__button",
                          attrs: {
                            title: _vm.$t("editor.menu.row-delete"),
                            disabled: !_vm.editor.can().deleteRow(),
                          },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().deleteRow().run()
                            },
                          },
                        },
                        [_c("icon", { attrs: { name: "delete_row" } })],
                        1
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _c("editor-content", {
            staticClass: "editor__content",
            attrs: { editor: _vm.editor, tabindex: "0" },
          }),
          _c("input", {
            ref: "fileUploadInput",
            staticStyle: { display: "none" },
            attrs: { type: "file" },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }