var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-form", [
        _c(
          "div",
          { staticClass: "group-one" },
          [
            _c(
              "span",
              { staticClass: "reset-icon" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "mb-2",
                    attrs: { size: "sm" },
                    on: { click: _vm.reset },
                  },
                  [
                    _c("BIconArrowClockwise", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      attrs: {
                        scale: "1.5",
                        title: _vm.$t("generic.reset-tooltip"),
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-form-group",
              {
                attrs: {
                  label: _vm.$t("poll.analysis.driving_time"),
                  "label-cols-sm": "4",
                },
              },
              [
                _c(
                  "b-input-group",
                  { staticClass: "mb-1" },
                  [
                    _c("b-form-input", {
                      attrs: {
                        "aria-label": _vm.$t("poll.analysis.from"),
                        placeholder: _vm.$t("poll.analysis.from"),
                        type: "number",
                        min: "0",
                      },
                      model: {
                        value: _vm.group.drivingMin,
                        callback: function ($$v) {
                          _vm.$set(_vm.group, "drivingMin", $$v)
                        },
                        expression: "group.drivingMin",
                      },
                    }),
                    _c("b-form-input", {
                      attrs: {
                        "aria-label": _vm.$t("poll.analysis.to"),
                        placeholder: _vm.$t("poll.analysis.to"),
                        type: "number",
                        min: "0",
                      },
                      model: {
                        value: _vm.group.drivingMax,
                        callback: function ($$v) {
                          _vm.$set(_vm.group, "drivingMax", $$v)
                        },
                        expression: "group.drivingMax",
                      },
                    }),
                    _c(
                      "b-input-group-append",
                      [
                        _c("b-input-group-text", [
                          _vm._v(_vm._s(_vm.$t("poll.analysis.years"))),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-form-group",
              {
                attrs: {
                  label: _vm.$t("poll.analysis.age"),
                  "label-cols-sm": "4",
                },
              },
              [
                _c(
                  "b-input-group",
                  { staticClass: "mb-1" },
                  [
                    _c("b-form-input", {
                      attrs: {
                        "aria-label": _vm.$t("poll.analysis.from"),
                        placeholder: _vm.$t("poll.analysis.from"),
                        type: "number",
                        min: "0",
                      },
                      model: {
                        value: _vm.group.ageMin,
                        callback: function ($$v) {
                          _vm.$set(_vm.group, "ageMin", $$v)
                        },
                        expression: "group.ageMin",
                      },
                    }),
                    _c("b-form-input", {
                      attrs: {
                        "aria-label": _vm.$t("poll.analysis.to"),
                        placeholder: _vm.$t("poll.analysis.to"),
                        type: "number",
                        min: "0",
                      },
                      model: {
                        value: _vm.group.ageMax,
                        callback: function ($$v) {
                          _vm.$set(_vm.group, "ageMax", $$v)
                        },
                        expression: "group.ageMax",
                      },
                    }),
                    _c(
                      "b-input-group-append",
                      [
                        _c("b-input-group-text", [
                          _vm._v(_vm._s(_vm.$t("poll.analysis.years"))),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-form-group",
              {
                attrs: {
                  label: _vm.$t("poll.analysis.sex"),
                  "label-cols-sm": "4",
                },
              },
              [
                _c(
                  "b-form-checkbox-group",
                  {
                    staticClass: "align-nicely",
                    attrs: { id: "sex", name: "sex" },
                    model: {
                      value: _vm.group.sex,
                      callback: function ($$v) {
                        _vm.$set(_vm.group, "sex", $$v)
                      },
                      expression: "group.sex",
                    },
                  },
                  [
                    _c("b-form-checkbox", { attrs: { value: "man" } }, [
                      _vm._v(_vm._s(_vm.$t("poll.analysis.men"))),
                    ]),
                    _c("b-form-checkbox", { attrs: { value: "woman" } }, [
                      _vm._v(_vm._s(_vm.$t("poll.analysis.women"))),
                    ]),
                    _c("span"),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-form-group",
              {
                staticClass: "pb-0",
                attrs: {
                  label: _vm.$t("poll.analysis.education"),
                  "label-cols-sm": "4",
                },
              },
              [
                _c(
                  "b-form-checkbox-group",
                  {
                    staticClass: "align-nicely",
                    attrs: { id: "edu", name: "edu" },
                    model: {
                      value: _vm.group.edu,
                      callback: function ($$v) {
                        _vm.$set(_vm.group, "edu", $$v)
                      },
                      expression: "group.edu",
                    },
                  },
                  [
                    _c("b-form-checkbox", { attrs: { value: "primary" } }, [
                      _vm._v(_vm._s(_vm.$t("poll.analysis.edu-primary"))),
                    ]),
                    _c("b-form-checkbox", { attrs: { value: "secondary" } }, [
                      _vm._v(_vm._s(_vm.$t("poll.analysis.edu-secondary"))),
                    ]),
                    _c("b-form-checkbox", { attrs: { value: "university" } }, [
                      _vm._v(_vm._s(_vm.$t("poll.analysis.edu-university"))),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-form-group",
              {
                attrs: {
                  label: _vm.$t("poll.analysis.vehicles"),
                  "label-cols-sm": "4",
                },
              },
              [
                _c(
                  "b-form-checkbox-group",
                  {
                    staticClass: "col-two",
                    attrs: { id: "vehicle", name: "vehicle" },
                    model: {
                      value: _vm.group.edu,
                      callback: function ($$v) {
                        _vm.$set(_vm.group, "edu", $$v)
                      },
                      expression: "group.edu",
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("b-form-checkbox", { attrs: { value: "bike" } }, [
                          _vm._v(_vm._s(_vm.$t("profile.vehicles.bike"))),
                        ]),
                        _c("b-form-checkbox", { attrs: { value: "bus" } }, [
                          _vm._v(_vm._s(_vm.$t("profile.vehicles.bus"))),
                        ]),
                        _c("b-form-checkbox", { attrs: { value: "car" } }, [
                          _vm._v(_vm._s(_vm.$t("profile.vehicles.car"))),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("b-form-checkbox", { attrs: { value: "truck" } }, [
                          _vm._v(_vm._s(_vm.$t("profile.vehicles.truck"))),
                        ]),
                        _c("b-form-checkbox", { attrs: { value: "van" } }, [
                          _vm._v(_vm._s(_vm.$t("profile.vehicles.van"))),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "b-form-group",
              {
                attrs: {
                  label: _vm.$t("poll.analysis.regions"),
                  "label-cols-sm": "4",
                },
              },
              [
                _c(
                  "b-form-checkbox-group",
                  {
                    staticClass: "align-nicely col-two",
                    attrs: { id: "regions", name: "regions" },
                    model: {
                      value: _vm.group.edu,
                      callback: function ($$v) {
                        _vm.$set(_vm.group, "edu", $$v)
                      },
                      expression: "group.edu",
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("b-form-checkbox", { attrs: { value: "PRG" } }, [
                          _vm._v(_vm._s(_vm.$t("profile.regions.PRG"))),
                        ]),
                        _c("b-form-checkbox", { attrs: { value: "SC" } }, [
                          _vm._v(_vm._s(_vm.$t("profile.regions.SC"))),
                        ]),
                        _c("b-form-checkbox", { attrs: { value: "JC" } }, [
                          _vm._v(_vm._s(_vm.$t("profile.regions.JC"))),
                        ]),
                        _c("b-form-checkbox", { attrs: { value: "PLS" } }, [
                          _vm._v(_vm._s(_vm.$t("profile.regions.PLS"))),
                        ]),
                        _c("b-form-checkbox", { attrs: { value: "KV" } }, [
                          _vm._v(_vm._s(_vm.$t("profile.regions.KV"))),
                        ]),
                        _c("b-form-checkbox", { attrs: { value: "UST" } }, [
                          _vm._v(_vm._s(_vm.$t("profile.regions.UST"))),
                        ]),
                        _c("b-form-checkbox", { attrs: { value: "LBR" } }, [
                          _vm._v(_vm._s(_vm.$t("profile.regions.LBR"))),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("b-form-checkbox", { attrs: { value: "KH" } }, [
                          _vm._v(_vm._s(_vm.$t("profile.regions.KH"))),
                        ]),
                        _c("b-form-checkbox", { attrs: { value: "PRD" } }, [
                          _vm._v(_vm._s(_vm.$t("profile.regions.PRD"))),
                        ]),
                        _c("b-form-checkbox", { attrs: { value: "VSC" } }, [
                          _vm._v(_vm._s(_vm.$t("profile.regions.VSC"))),
                        ]),
                        _c("b-form-checkbox", { attrs: { value: "JM" } }, [
                          _vm._v(_vm._s(_vm.$t("profile.regions.JM"))),
                        ]),
                        _c("b-form-checkbox", { attrs: { value: "OLM" } }, [
                          _vm._v(_vm._s(_vm.$t("profile.regions.OLM"))),
                        ]),
                        _c("b-form-checkbox", { attrs: { value: "ZLN" } }, [
                          _vm._v(_vm._s(_vm.$t("profile.regions.ZLN"))),
                        ]),
                        _c("b-form-checkbox", { attrs: { value: "MS" } }, [
                          _vm._v(_vm._s(_vm.$t("profile.regions.MS"))),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }