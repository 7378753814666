var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationProvider", {
    attrs: {
      mode: "eager",
      tag: "div",
      vid: _vm.vid,
      rules: _vm.rules,
      name: _vm.name || _vm.label,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var errors = ref.errors
          var required = ref.required
          var ariaMsg = ref.ariaMsg
          return [
            _c(
              "div",
              [
                _c(
                  "label",
                  {
                    attrs: { for: _vm.name },
                    on: {
                      click: function ($event) {
                        return _vm.$refs.input.focus()
                      },
                    },
                  },
                  [
                    _vm.label
                      ? _c("div", [
                          _c("span", { staticClass: "d-label" }, [
                            _vm._v(_vm._s(_vm.label)),
                          ]),
                          _c("span", [_vm._v(_vm._s(required ? " *" : ""))]),
                        ])
                      : _vm._e(),
                  ]
                ),
                _c("datepicker", {
                  ref: "input",
                  class: { "is-invalid": errors[0], "has-value": _vm.hasValue },
                  attrs: {
                    id: _vm.name,
                    format: _vm.format,
                    "minimum-view": _vm.minimumView,
                    "bootstrap-styling": true,
                    "disabled-dates": _vm.disabledDates,
                    required: required,
                    type: _vm.type,
                    typeable: true,
                    name: _vm.name,
                  },
                  model: {
                    value: _vm.innerValue,
                    callback: function ($$v) {
                      _vm.innerValue = $$v
                    },
                    expression: "innerValue",
                  },
                }),
                errors[0]
                  ? _c(
                      "div",
                      _vm._b(
                        { staticClass: "p-1 text-danger" },
                        "div",
                        ariaMsg,
                        false
                      ),
                      [_vm._v(" " + _vm._s(errors[0]) + " ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }