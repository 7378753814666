var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-3 comment-wrap", attrs: { id: _vm.comment._id } },
    [
      _c("div", { staticClass: "first-com-box" }, [
        _c("div", { staticClass: "author-det" }, [
          _c(
            "span",
            [
              _c("BIconPersonCircle", { attrs: { scale: "1" } }),
              _c("ProfileLink", { attrs: { profile: _vm.comment.author } }),
            ],
            1
          ),
          _c(
            "span",
            [
              _c("BIconClock", { attrs: { scale: "1" } }),
              _c("Date", {
                attrs: { date: this.comment.date, format: "dynamicDate" },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "comments" }, [
          _c("div", { staticClass: "pt-2 pb-2" }, [
            _c("span", { domProps: { innerHTML: _vm._s(_vm.comment.text) } }),
          ]),
          _c("div", { staticClass: "comment-buttons" }, [
            _c(
              "div",
              [
                _c(
                  "b-button",
                  {
                    staticClass: "mr-1",
                    attrs: { variant: "outline-secondary", size: "sm" },
                    on: { click: _vm.reply },
                  },
                  [
                    _c("BIconChat", { attrs: { "aria-hidden": "true" } }),
                    _vm._v(" " + _vm._s(_vm.$t("comment.reply")) + " "),
                  ],
                  1
                ),
                _c(
                  "span",
                  { attrs: { id: "upvotes_" + _vm.comment._id } },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "mr-1",
                        attrs: {
                          disabled: !_vm.canVote,
                          variant: "outline-secondary",
                          size: "sm",
                        },
                        on: { click: _vm.upvote },
                      },
                      [
                        _c("BIconHandThumbsUp", {
                          attrs: { "aria-hidden": "true" },
                        }),
                        _vm._v(" " + _vm._s(_vm.comment.up) + " "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "span",
                  { attrs: { id: "downvotes_" + _vm.comment._id } },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "mr-2",
                        attrs: {
                          disabled: !_vm.canVote,
                          variant: "outline-secondary",
                          size: "sm",
                        },
                        on: { click: _vm.downvote },
                      },
                      [
                        _c("BIconHandThumbsDown", {
                          attrs: { "aria-hidden": "true" },
                        }),
                        _vm._v(" " + _vm._s(_vm.comment.down) + " "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-popover",
                  {
                    attrs: {
                      target: "upvotes_" + _vm.comment._id,
                      triggers: "hover",
                      placement: "top",
                    },
                  },
                  _vm._l(_vm.upvotes, function (vote) {
                    return _c(
                      "div",
                      { key: vote._id },
                      [
                        _c("ProfileLink", {
                          attrs: {
                            profile: vote.user,
                            "show-user-info": false,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _c(
                  "b-popover",
                  {
                    attrs: {
                      target: "downvotes_" + _vm.comment._id,
                      triggers: "hover",
                      placement: "top",
                    },
                  },
                  _vm._l(_vm.downvotes, function (vote) {
                    return _c(
                      "div",
                      { key: vote._id },
                      [
                        _c("ProfileLink", {
                          attrs: {
                            profile: vote.user,
                            "show-user-info": false,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "act-btn" },
              [
                !_vm.comment.parentId
                  ? _c(
                      "b-button",
                      {
                        staticClass: "mr-2",
                        attrs: { variant: "outline-secondary", size: "sm" },
                        on: { click: _vm.reload },
                      },
                      [
                        _c("BIconArrowClockwise", {
                          attrs: { "aria-hidden": "true" },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.collapseId
                  ? _c(
                      "b-button",
                      {
                        staticClass: "float-right",
                        attrs: { variant: "outline-secondary", size: "sm" },
                        on: { click: _vm.collapse },
                      },
                      [
                        _vm.collapsed
                          ? _c("BIconArrowsExpand", {
                              attrs: { "aria-hidden": "true" },
                            })
                          : _vm._e(),
                        !_vm.collapsed
                          ? _c("BIconArrowsCollapse", {
                              attrs: { "aria-hidden": "true" },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "replying-box" }, [
        _vm.replying
          ? _c(
              "div",
              [
                _c("CommentForm", {
                  attrs: {
                    isShow: _vm.replying,
                    itemId: _vm.itemId,
                    parent: _vm.replyId,
                    commentId: _vm.comment._id,
                  },
                  on: { dismiss: _vm.dismiss },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }