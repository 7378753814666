var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    _vm._l(_vm.tags, function (tag) {
      return _c(
        "router-link",
        { key: tag, attrs: { to: { name: "tag", params: { tag: tag } } } },
        [_vm._v(" #" + _vm._s(tag) + " ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }