var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "icon",
      class: [
        "icon--" + _vm.name,
        "icon--" + _vm.size,
        { "has-align-fix": _vm.fixAlign },
      ],
    },
    [
      _c("img", {
        staticClass: "icon__svg",
        attrs: { src: "/images/editor/" + _vm.name + ".svg" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }