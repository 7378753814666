var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "comment__child" },
    [
      _vm._l(_vm.replies, function (reply) {
        return _c(
          "div",
          { key: reply._id },
          [_c("Comment", { attrs: { itemId: _vm.itemId, comment: reply } })],
          1
        )
      }),
      !_vm.comment.allShown
        ? _c("Button", {
            staticClass: "mb-2",
            attrs: { value: _vm.$t("comment.show-all"), size: "sm" },
            on: {
              clicked: function ($event) {
                return _vm.showAll()
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }