var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-3 centerbox m-auto" },
    [
      _c("div", { staticClass: "head-area" }, [
        _c("h2", [_vm._v(_vm._s(_vm.$t("page-title.pages")))]),
      ]),
      _c(
        "div",
        { staticClass: "mb-2 d-flex flex-row-reverse action-btn" },
        [
          _c(
            "b-button-group",
            [
              _vm.role
                ? _c(
                    "b-button",
                    {
                      attrs: {
                        to: { name: "create-page" },
                        variant: "outline-primary",
                      },
                    },
                    [
                      _c("BIconFileEarmarkPlusFill", { attrs: { scale: "1" } }),
                      _vm._v(
                        " " + _vm._s(_vm.$t("pages.new-page-button")) + " "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._l(_vm.cmsList, function (item) {
        return _c(
          "div",
          { key: item._id, staticClass: "pagelist-box" },
          [
            _c(
              "b-card",
              { attrs: { tag: "article" } },
              [
                _c("b-card-body", [
                  _c(
                    "h3",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "page",
                              params: { slug: item.info.slug },
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.info.caption) + " ")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "b-card-footer",
                  [
                    _c("div", [
                      _c(
                        "span",
                        [
                          _c("BIconClock", { attrs: { scale: "1" } }),
                          _c("Date", {
                            attrs: {
                              date: item.info.date,
                              format: "dynamicDate",
                            },
                          }),
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "generic.content.state." + item.info.state
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        [
                          _c("BIconPersonCircle", { attrs: { scale: "1" } }),
                          _c("ProfileLink", {
                            attrs: { profile: item.info.author },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "b-button-group",
                      [
                        _vm.role
                          ? _c(
                              "b-button",
                              {
                                attrs: {
                                  to: {
                                    name: "edit-page",
                                    params: { slug: item.info.slug },
                                  },
                                  variant: "outline-primary",
                                },
                              },
                              [
                                _c("BIconPencilSquare", {
                                  attrs: { scale: "1" },
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("generic.edit-button")) +
                                    " "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.role
                          ? _c(
                              "b-button",
                              {
                                attrs: { variant: "outline-primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.confirmDelete(item)
                                  },
                                },
                              },
                              [
                                _c("BIconTrash", { attrs: { scale: "1" } }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("generic.delete-button")) +
                                    " "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }