var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-2 mt-2" },
    [
      _c(
        "b-popover",
        {
          attrs: {
            target: "emojis_" + _vm.commentId,
            triggers: "hover",
            placement: "bottom",
          },
        },
        _vm._l(_vm.emojiArray, function (emoji, index) {
          return _c(
            "b-button",
            {
              key: index,
              attrs: { variant: "outline", size: "sm" },
              on: {
                click: function ($event) {
                  return _vm.addEmoji(index)
                },
              },
            },
            [_vm._v(" " + _vm._s(emoji) + " ")]
          )
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "comment-box" },
        [
          _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "editor-js",
                      attrs: { sm: "12", id: "editor-js-" + _vm.commentId },
                    },
                    [
                      _vm.editor
                        ? _c(
                            "div",
                            { staticClass: "menubar" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "menubar__button",
                                  attrs: {
                                    id: "emojis_" + _vm.commentId,
                                    variant: "outline",
                                  },
                                },
                                [_c("BIconEmojiSunglasses")],
                                1
                              ),
                              !_vm.editor.isActive("link")
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "menubar__button",
                                      attrs: {
                                        title: _vm.$t("editor.menu.link"),
                                      },
                                      on: { click: _vm.showLinkMenu },
                                    },
                                    [_c("icon", { attrs: { name: "link" } })],
                                    1
                                  )
                                : _vm._e(),
                              _vm.editor.isActive("link")
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "is-active menubar__button",
                                      attrs: {
                                        title: _vm.$t("editor.menu.unlink"),
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.editor
                                            .chain()
                                            .focus()
                                            .unsetLink()
                                            .run()
                                        },
                                      },
                                    },
                                    [_c("icon", { attrs: { name: "remove" } })],
                                    1
                                  )
                                : _vm._e(),
                              _vm.editor.isActive("link")
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "is-active menubar__button",
                                      attrs: {
                                        title: _vm.$t("editor.menu.link-edit"),
                                      },
                                      on: { click: _vm.showLinkMenu },
                                    },
                                    [_c("icon", { attrs: { name: "link" } })],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "button",
                                {
                                  staticClass: "menubar__button",
                                  class: {
                                    "is-active": _vm.editor.isActive("bold"),
                                  },
                                  attrs: { title: _vm.$t("editor.menu.bold") },
                                  on: {
                                    click: function ($event) {
                                      _vm.editor
                                        .chain()
                                        .focus()
                                        .toggleBold()
                                        .run()
                                    },
                                  },
                                },
                                [_c("icon", { attrs: { name: "bold" } })],
                                1
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "menubar__button",
                                  class: {
                                    "is-active": _vm.editor.isActive("italic"),
                                  },
                                  attrs: {
                                    title: _vm.$t("editor.menu.italic"),
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.editor
                                        .chain()
                                        .focus()
                                        .toggleItalic()
                                        .run()
                                    },
                                  },
                                },
                                [_c("icon", { attrs: { name: "italic" } })],
                                1
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "menubar__button",
                                  class: {
                                    "is-active":
                                      _vm.editor.isActive("codeBlock"),
                                  },
                                  attrs: { title: _vm.$t("editor.menu.quote") },
                                  on: {
                                    click: function ($event) {
                                      _vm.editor
                                        .chain()
                                        .focus()
                                        .toggleCodeBlock()
                                        .run()
                                    },
                                  },
                                },
                                [_c("icon", { attrs: { name: "quote" } })],
                                1
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "menubar__button",
                                  class: {
                                    "is-active": _vm.editor.isActive("strike"),
                                  },
                                  attrs: {
                                    title: _vm.$t("editor.menu.strike"),
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.editor
                                        .chain()
                                        .focus()
                                        .toggleStrike()
                                        .run()
                                    },
                                  },
                                },
                                [_c("icon", { attrs: { name: "strike" } })],
                                1
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "menubar__button",
                                  class: {
                                    "is-active":
                                      _vm.editor.isActive("underline"),
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.editor
                                        .chain()
                                        .focus()
                                        .toggleUnderline()
                                        .run()
                                    },
                                  },
                                },
                                [_c("icon", { attrs: { name: "underline" } })],
                                1
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "menubar__button",
                                  class: {
                                    "is-active":
                                      _vm.editor.isActive("bulletList"),
                                  },
                                  attrs: { title: _vm.$t("editor.menu.ul") },
                                  on: {
                                    click: function ($event) {
                                      _vm.editor
                                        .chain()
                                        .focus()
                                        .toggleBulletList()
                                        .run()
                                    },
                                  },
                                },
                                [_c("icon", { attrs: { name: "ul" } })],
                                1
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "menubar__button",
                                  class: {
                                    "is-active":
                                      _vm.editor.isActive("orderedList"),
                                  },
                                  attrs: { title: _vm.$t("editor.menu.ol") },
                                  on: {
                                    click: function ($event) {
                                      _vm.editor
                                        .chain()
                                        .focus()
                                        .toggleOrderedList()
                                        .run()
                                    },
                                  },
                                },
                                [_c("icon", { attrs: { name: "ol" } })],
                                1
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "menubar__button",
                                  attrs: { title: _vm.$t("editor.menu.undo") },
                                  on: {
                                    click: function ($event) {
                                      _vm.editor.chain().focus().undo().run()
                                    },
                                  },
                                },
                                [_c("icon", { attrs: { name: "undo" } })],
                                1
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "menubar__button",
                                  attrs: { title: _vm.$t("editor.menu.redo") },
                                  on: {
                                    click: function ($event) {
                                      _vm.editor.chain().focus().redo().run()
                                    },
                                  },
                                },
                                [_c("icon", { attrs: { name: "redo" } })],
                                1
                              ),
                              _vm.parent
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "closebtn",
                                      attrs: { variant: "outline", size: "sm" },
                                      on: { click: _vm.dismiss },
                                    },
                                    [_c("BIconXCircle")],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isShow
                        ? _c("editor-content", {
                            attrs: { editor: _vm.editor },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-alert",
        {
          attrs: { variant: "danger", dismissible: "" },
          model: {
            value: _vm.error,
            callback: function ($$v) {
              _vm.error = $$v
            },
            expression: "error",
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("generic.internal-error")) + " ")]
      ),
      _c(
        "span",
        { staticClass: "send-comment-btn" },
        [
          _c("Button", {
            staticClass: "mt-2",
            attrs: {
              disabled: _vm.sending || _vm.empty,
              size: "sm",
              value: _vm.$t("comment.send-button"),
            },
            on: { clicked: _vm.send },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }