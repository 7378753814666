var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-3 mt-5 centerbox" },
    [
      _c("vue-word-cloud", {
        staticClass: "tags",
        staticStyle: {
          "font-family": "fantasy",
          width: "100%",
          "min-height": "250px",
          border: "5px dashed #e6eaef!important",
          "border-bottom-width": "10px!important",
        },
        attrs: {
          words: _vm.tags,
          color: function (ref) {
            var weight = ref[1]

            return weight > 5 ? "#ffc107" : weight > 3 ? "RoyalBlue" : "Indigo"
          },
          "font-family": "fantasy",
          spacing: 0.1,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var text = ref.text
              var weight = ref.weight
              var word = ref.word
              return [
                _c("div", [
                  _c(
                    "div",
                    {
                      staticStyle: { cursor: "pointer" },
                      attrs: { title: weight },
                      on: {
                        click: function ($event) {
                          return _vm.onTagClick(word)
                        },
                      },
                    },
                    [_c("div", [_vm._v(_vm._s(text))])]
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "items-with-tags mt-4" },
        [
          !_vm.items
            ? _c("ContentLoading", { attrs: { type: "items" } })
            : _vm._e(),
        ],
        1
      ),
      _vm.tag ? _c("ItemList", { attrs: { tag: _vm.tag } }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }