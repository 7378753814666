var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "comment-area", attrs: { id: "comments" } },
    [
      _c("h3", [_vm._v(_vm._s(_vm.$t("comment.discussion")))]),
      _vm.signedIn
        ? _c(
            "div",
            [
              _c("CommentForm", {
                attrs: { isShow: true, itemId: _vm.itemId },
              }),
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "sign-in",
                      params: { redirectUrl: this.$route.fullPath },
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("comment.sign-in")) + " ")]
              ),
            ],
            1
          ),
      _vm._l(_vm.comments, function (comment) {
        return _c(
          "div",
          { key: comment._id },
          [
            _c("Comment", {
              attrs: {
                itemId: _vm.itemId,
                comment: comment,
                collapseId: _vm.getCollapseId(comment),
              },
            }),
            _c(
              "b-collapse",
              { attrs: { id: "replies_" + comment._id, visible: "" } },
              [
                comment.replies && comment.replies.length > 0
                  ? _c("Replies", {
                      attrs: { itemId: _vm.itemId, comment: comment },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c(
        "span",
        { staticClass: "load-comments" },
        [
          _vm.incomplete
            ? _c("Button", {
                attrs: { value: _vm.$t("comment.load-more"), size: "sm" },
                on: {
                  clicked: function ($event) {
                    return _vm.loadMoreComments(_vm.itemId)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }