var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-3 mt-5 border centerbox" },
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("div", { staticClass: "head-area" }, [
              _c("h2", [_vm._v(_vm._s(_vm.$t("sign-up.verify-heading")))]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _vm.verified === null
            ? _c("b-col", [
                _c("p", { staticClass: "text-success" }, [
                  _vm._v(_vm._s(_vm.$t("sign-up.verify-running"))),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _vm.verified === false
            ? _c("b-col", [
                _c("p", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.$t("sign-up.verify-error"))),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }