var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-form-input", {
    staticClass: "bg-warning",
    attrs: { type: "submit", value: _vm.value },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }