var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-5 border centerbox" },
    [
      _c("div", { staticClass: "head-area" }, [
        _c("h2", [_vm._v(_vm._s(_vm.$t("page-title.create-page")))]),
      ]),
      _c("PageForm"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }