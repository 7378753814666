var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "pt-3 m-auto pb-3" },
      [
        _vm.poll ? _c("HomePoll", { attrs: { poll: _vm.poll } }) : _vm._e(),
        _c("AccidentsSummary"),
        _c("ItemList", { attrs: { exceptItem: _vm.poll } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }