var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mt-5 border centerbox" }, [
    _c("div", { staticClass: "head-area" }, [
      _c("h2", [_vm._v(_vm._s(_vm.$t("sign-up.resend-heading")))]),
      _c("p", [_vm._v(_vm._s(_vm.$t("sign-up.resend-description")))]),
    ]),
    !_vm.finished
      ? _c(
          "div",
          [
            _c("ValidationObserver", {
              ref: "form",
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var passes = ref.passes
                      return [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return passes(_vm.sendActivationEmail)
                              },
                            },
                          },
                          [
                            _c(
                              "fieldset",
                              { attrs: { disabled: _vm.disableForm } },
                              [
                                _vm.error
                                  ? _c("div", { staticClass: "text-danger" }, [
                                      _c("strong", [
                                        _vm._v(" " + _vm._s(_vm.error) + " "),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "field-area" },
                                  [
                                    _c("TextInput", {
                                      attrs: {
                                        rules: "required|email",
                                        name: "email",
                                        type: "email",
                                        label: _vm.$t("profile.email"),
                                        placeholder: _vm.$t(
                                          "generic.email-placeholder"
                                        ),
                                      },
                                      model: {
                                        value: _vm.email,
                                        callback: function ($$v) {
                                          _vm.email = $$v
                                        },
                                        expression: "email",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("Button", {
                                  staticClass: "reset-btn",
                                  attrs: {
                                    waiting: _vm.sending,
                                    value: _vm.$t("sign-up.resend-button"),
                                  },
                                  on: { clicked: _vm.sendActivationEmail },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                3961031194
              ),
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.finished === true
      ? _c("div", [
          _c("p", { staticClass: "text-success" }, [
            _vm._v(_vm._s(_vm.$t("sign-up.resend-success"))),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }