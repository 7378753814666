var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "router-link",
        {
          attrs: {
            to: { name: "user-profile", params: { id: _vm.profile.id } },
            id: _vm.id,
          },
        },
        [_vm._v(" " + _vm._s(_vm.profile.nickname) + " ")]
      ),
      _vm.showUserInfo
        ? _c("b-popover", {
            attrs: {
              target: _vm.id,
              triggers: "hover",
              placement: "top",
              delay: "150",
            },
            on: { show: _vm.onShow },
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function () {
                    return [
                      _vm.userInfo
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.userInfo.bio.nickname)),
                          ])
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
                _vm.userInfo
                  ? {
                      key: "default",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("profile.member-since-label")) +
                                  ": "
                              ),
                              _c("Date", {
                                attrs: {
                                  date: _vm.userInfo.bio.registered,
                                  format: "dynamicDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("profile.rank-label")) +
                                ": " +
                                _vm._s(
                                  _vm.$t(
                                    "profile.rank." + _vm.userInfo.honors.rank
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("profile.honors.comments-label")
                                ) +
                                ": " +
                                _vm._s(_vm.userInfo.honors.count.comments) +
                                " "
                            ),
                          ]),
                          _vm.userInfo.honors.count.commentVoteRatio
                            ? _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "profile.honors.comment-ratio-label"
                                      )
                                    ) +
                                    ": " +
                                    _vm._s(
                                      _vm.userInfo.honors.count.commentVoteRatio
                                    ) +
                                    "% "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }