var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pages-wrap" }, [
    _c(
      "div",
      { staticClass: "pt-3 ml-auto mr-auto mt-auto mb-5" },
      [
        !_vm.content
          ? _c("ContentLoading", { attrs: { type: "cms" } })
          : _vm._e(),
        _vm.content
          ? _c(
              "div",
              { staticClass: "page-content-wrap" },
              [
                _c("div", {
                  staticClass: "post-content",
                  domProps: { innerHTML: _vm._s(_vm.content.data.content) },
                }),
                _c("ShareLink", { attrs: { item: _vm.content } }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }