var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-2" },
    [
      _c("ValidationObserver", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var handleSubmit = ref.handleSubmit
              return [
                _c(
                  "b-form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return handleSubmit(_vm.onSubmit)
                      },
                    },
                  },
                  [
                    _c("ValidationProvider", {
                      attrs: { rules: { required: true, min: 3 } },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (validationContext) {
                              return [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "caption-group",
                                      label: _vm.$t(
                                        "generic.content.caption-label"
                                      ),
                                      "label-for": "caption",
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "caption",
                                        name: "caption",
                                        "aria-describedby": "text-errors",
                                        state:
                                          _vm.getValidationState(
                                            validationContext
                                          ),
                                      },
                                      model: {
                                        value: _vm.form.caption,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "caption", $$v)
                                        },
                                        expression: "form.caption",
                                      },
                                    }),
                                    _c(
                                      "b-form-invalid-feedback",
                                      { attrs: { id: "caption-errors" } },
                                      [
                                        _vm._v(
                                          _vm._s(validationContext.errors[0])
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _c("ValidationProvider", {
                      attrs: { rules: { required: true, min: 3 } },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (validationContext) {
                              return [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "slug-group",
                                      label: _vm.$t(
                                        "generic.content.slug-label"
                                      ),
                                      "label-for": "slug",
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "slug",
                                        name: "slug",
                                        "aria-describedby": "text-errors",
                                        state:
                                          _vm.getValidationState(
                                            validationContext
                                          ),
                                      },
                                      model: {
                                        value: _vm.form.slug,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "slug", $$v)
                                        },
                                        expression: "form.slug",
                                      },
                                    }),
                                    _c(
                                      "b-form-invalid-feedback",
                                      { attrs: { id: "slug-errors" } },
                                      [
                                        _vm._v(
                                          _vm._s(validationContext.errors[0])
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _c("Editor", {
                      attrs: { blog: _vm.form.content },
                      on: { changeBlog: _vm.changeBlog },
                    }),
                    _vm.error
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(" " + _vm._s(_vm.error) + " "),
                        ])
                      : _vm._e(),
                    _c(
                      "b-button",
                      { attrs: { type: "submit", variant: "primary" } },
                      [_vm._v(_vm._s(_vm.$t("generic.save-button")))]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }