var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mb-3" }, [
    _vm.user.honors.rank === "novice"
      ? _c("ul", { staticClass: "ranks-list" }, [
          _c(
            "li",
            [
              _c("BIconAward", { attrs: { scale: "1.5" } }),
              _vm._v(
                " " +
                  _vm._s(_vm.$t("profile.honors.next-rank-label")) +
                  ": " +
                  _vm._s(_vm.$t("profile.rank.student")) +
                  " "
              ),
            ],
            1
          ),
          _c("li", [
            _c("span", { staticClass: "mr-2" }, [
              _vm._v(_vm._s(_vm.$t("profile.honors.poll-votes-label")) + ":"),
            ]),
            _c(
              "div",
              [
                _vm.pollVotesCount >= 1
                  ? _c("BIconCheck2Circle", {
                      staticClass: "my-auto",
                      attrs: { scale: "1" },
                    })
                  : _vm._e(),
                _vm.pollVotesCount < 1
                  ? _c("b-progress", {
                      staticClass: "w-25 my-auto",
                      attrs: {
                        value: _vm.pollVotesCount,
                        max: "1",
                        "show-value": "",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("li", [
            _c("span", { staticClass: "mr-2" }, [
              _vm._v(_vm._s(_vm.$t("profile.honors.comments-label")) + ":"),
            ]),
            _c(
              "div",
              [
                _vm.commentsCount < 1
                  ? _c("b-progress", {
                      staticClass: "w-25 my-auto",
                      attrs: {
                        value: _vm.commentsCount,
                        max: "1",
                        "show-value": "",
                      },
                    })
                  : _vm._e(),
                _vm.commentsCount >= 1
                  ? _c("BIconCheck2Circle", {
                      staticClass: "my-auto",
                      attrs: { "font-scale": "1" },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("li", [
            _c("span", { staticClass: "mr-2" }, [
              _vm._v(
                _vm._s(_vm.$t("profile.honors.comment-votes-label")) + ":"
              ),
            ]),
            _c(
              "div",
              [
                _vm.commentVotesCount < 1
                  ? _c("b-progress", {
                      staticClass: "w-25 my-auto",
                      attrs: {
                        value: _vm.commentVotesCount,
                        max: "1",
                        "show-value": "",
                      },
                    })
                  : _vm._e(),
                _vm.commentVotesCount >= 1
                  ? _c("BIconCheck2Circle", {
                      staticClass: "my-auto",
                      attrs: { "font-scale": "1" },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("li", [
            _c("span", { staticClass: "mr-2" }, [
              _vm._v(_vm._s(_vm.$t("profile.honors.shares-label")) + ":"),
            ]),
            _c(
              "div",
              [
                _vm.user.honors.count.shares >= 1
                  ? _c("BIconCheck2Circle", {
                      staticClass: "my-auto",
                      attrs: { "font-scale": "1" },
                    })
                  : _vm._e(),
                _vm.user.honors.count.shares < 1
                  ? _c("b-progress", {
                      staticClass: "w-25 my-auto",
                      attrs: {
                        value: _vm.user.honors.count.shares,
                        max: "1",
                        "show-value": "",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _vm.user.honors.rank === "student"
      ? _c("ul", { staticClass: "ranks-list" }, [
          _c(
            "li",
            [
              _c("BIconAward", { attrs: { "font-scale": "1" } }),
              _vm._v(
                " " +
                  _vm._s(_vm.$t("profile.honors.next-rank-label")) +
                  ": " +
                  _vm._s(_vm.$t("profile.rank.graduate")) +
                  " "
              ),
            ],
            1
          ),
          _c("li", [
            _vm._v(
              " " + _vm._s(_vm.$t("profile.honors.requirements-label")) + " "
            ),
          ]),
          _c("li", [
            _c("span", { staticClass: "mr-2" }, [
              _vm._v(_vm._s(_vm.$t("profile.honors.poll-votes-label")) + ":"),
            ]),
            _c(
              "div",
              [
                _vm.pollVotesCount >= 3
                  ? _c("BIconCheck2Circle", {
                      staticClass: "my-auto",
                      attrs: { "font-scale": "1" },
                    })
                  : _vm._e(),
                _vm.pollVotesCount < 3
                  ? _c("b-progress", {
                      staticClass: "w-25 my-auto",
                      attrs: {
                        value: _vm.pollVotesCount,
                        max: "3",
                        "show-value": "",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("li", [
            _c("span", { staticClass: "mr-2" }, [
              _vm._v(_vm._s(_vm.$t("profile.honors.blogs-label")) + ":"),
            ]),
            _c(
              "div",
              [
                _vm.blogsCount >= 1
                  ? _c("BIconCheck2Circle", {
                      staticClass: "my-auto",
                      attrs: { "font-scale": "1" },
                    })
                  : _vm._e(),
                _vm.blogsCount < 1
                  ? _c("b-progress", {
                      staticClass: "w-25 my-auto",
                      attrs: {
                        value: _vm.blogsCount,
                        max: "1",
                        "show-value": "",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("li", [
            _c("span", { staticClass: "mr-2" }, [
              _vm._v(
                _vm._s(_vm.$t("profile.honors.comment-ratio-label")) + ":"
              ),
            ]),
            _c(
              "div",
              [
                _vm.commentVoteRatio >= 66
                  ? _c("BIconCheck2Circle", {
                      staticClass: "my-auto",
                      attrs: { "font-scale": "1" },
                    })
                  : _vm._e(),
                _vm.commentVoteRatio < 66
                  ? _c("b-progress", {
                      staticClass: "w-25 my-auto",
                      attrs: {
                        value: _vm.commentVoteRatio,
                        max: "66",
                        "show-value": "",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("li", [
            _c("span", { staticClass: "mr-2" }, [
              _vm._v(_vm._s(_vm.$t("profile.honors.shares-label")) + ":"),
            ]),
            _c(
              "div",
              [
                _vm.sharesCount >= 10
                  ? _c("BIconCheck2Circle", {
                      staticClass: "my-auto",
                      attrs: { "font-scale": "1" },
                    })
                  : _vm._e(),
                _vm.sharesCount < 10
                  ? _c("b-progress", {
                      staticClass: "w-25 my-auto",
                      attrs: {
                        value: _vm.sharesCount,
                        max: "10",
                        "show-value": "",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _vm.user.honors.rank === "graduate"
      ? _c("ul", { staticClass: "ranks-list" }, [
          _c(
            "li",
            [
              _c("BIconAward", { attrs: { "font-scale": "2" } }),
              _vm._v(
                " " +
                  _vm._s(_vm.$t("profile.honors.next-rank-label")) +
                  ": " +
                  _vm._s(_vm.$t("profile.rank.expert")) +
                  " "
              ),
            ],
            1
          ),
          _c("li", [
            _vm._v(
              " " + _vm._s(_vm.$t("profile.honors.requirements-label")) + " "
            ),
          ]),
          _c("li", [
            _c("span", { staticClass: "mr-2" }, [
              _vm._v(_vm._s(_vm.$t("profile.honors.poll-votes-label")) + ":"),
            ]),
            _c(
              "div",
              [
                _vm.pollVotesCount >= 10
                  ? _c("BIconCheck2Circle", {
                      staticClass: "my-auto",
                      attrs: { scale: "1" },
                    })
                  : _vm._e(),
                _vm.pollVotesCount < 10
                  ? _c("b-progress", {
                      staticClass: "w-25 my-auto",
                      attrs: {
                        value: _vm.pollVotesCount,
                        max: "10",
                        "show-value": "",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("li", [
            _c("span", { staticClass: "mr-2" }, [
              _vm._v(_vm._s(_vm.$t("profile.honors.blogs-label")) + ":"),
            ]),
            _c(
              "div",
              [
                _vm.blogsCount >= 10
                  ? _c("BIconCheck2Circle", {
                      staticClass: "my-auto",
                      attrs: { scale: "1" },
                    })
                  : _vm._e(),
                _vm.blogsCount < 10
                  ? _c("b-progress", {
                      staticClass: "w-25 my-auto",
                      attrs: {
                        value: _vm.blogsCount,
                        max: "10",
                        "show-value": "",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("li", [
            _c("span", { staticClass: "mr-2" }, [
              _vm._v(_vm._s(_vm.$t("profile.honors.comments-label")) + ":"),
            ]),
            _c(
              "div",
              [
                _vm.commentsCount >= 100
                  ? _c("BIconCheck2Circle", {
                      staticClass: "my-auto",
                      attrs: { scale: "1" },
                    })
                  : _vm._e(),
                _vm.commentsCount < 100
                  ? _c("b-progress", {
                      staticClass: "w-25 my-auto",
                      attrs: {
                        value: _vm.commentsCount,
                        max: "100",
                        "show-value": "",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("li", [
            _c("span", { staticClass: "mr-2" }, [
              _vm._v(
                _vm._s(_vm.$t("profile.honors.comment-ratio-label")) + ":"
              ),
            ]),
            _c(
              "div",
              [
                _vm.commentVoteRatio >= 80
                  ? _c("BIconCheck2Circle", {
                      staticClass: "my-auto",
                      attrs: { scale: "1" },
                    })
                  : _vm._e(),
                _vm.commentVoteRatio < 80
                  ? _c("b-progress", {
                      staticClass: "w-25 my-auto",
                      attrs: {
                        value: _vm.commentVoteRatio,
                        max: "80",
                        "show-value": "",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("li", [
            _c("span", { staticClass: "mr-2" }, [
              _vm._v(
                _vm._s(_vm.$t("profile.honors.sharing-weeks-label")) + ":"
              ),
            ]),
            _c(
              "div",
              [
                _vm.sharingWeeksCount >= 10
                  ? _c("BIconCheck2Circle", {
                      staticClass: "my-auto",
                      attrs: { scale: "1" },
                    })
                  : _vm._e(),
                _vm.sharingWeeksCount < 10
                  ? _c("b-progress", {
                      staticClass: "w-25 my-auto",
                      attrs: {
                        value: _vm.sharingWeeksCount,
                        max: "10",
                        "show-value": "",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }