var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-button",
    {
      attrs: {
        size: _vm.size,
        type: "submit",
        disabled: _vm.disabled,
        variant: "warning",
      },
      on: { click: _vm.clicked },
    },
    [
      _vm.waiting ? _c("b-spinner") : _vm._e(),
      _vm.waiting === false
        ? _c("span", [_vm._v(_vm._s(_vm.value))])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }