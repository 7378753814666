var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.type === "poll" || _vm.type === "generic"
        ? _c("content-loader", { attrs: { height: 160, width: 400 } }, [
            _c("rect", {
              attrs: { x: "0", y: "0", width: "400", height: "40" },
            }),
            _c("rect", {
              attrs: { x: "0", y: "60", width: "200", height: "20" },
            }),
            _c("rect", {
              attrs: { x: "0", y: "100", width: "50", height: "40" },
            }),
            _c("rect", {
              attrs: { x: "80", y: "100", width: "50", height: "40" },
            }),
            _c("rect", {
              attrs: { x: "160", y: "100", width: "50", height: "40" },
            }),
            _c("rect", {
              attrs: { x: "240", y: "100", width: "50", height: "40" },
            }),
          ])
        : _vm._e(),
      _vm.type === "items"
        ? _c("content-loader", { attrs: { height: 160, width: 400 } }, [
            _c("rect", {
              attrs: { x: "30", y: "100", width: "270", height: "270" },
            }),
            _c("rect", {
              attrs: { x: "330", y: "100", width: "270", height: "270" },
            }),
            _c("rect", {
              attrs: { x: "730", y: "100", width: "270", height: "270" },
            }),
          ])
        : _vm._e(),
      _vm.type === "profile"
        ? _c("content-loader", { attrs: { height: 100, width: 400 } }, [
            _c("rect", {
              attrs: {
                x: "9",
                y: "12",
                rx: "3",
                ry: "3",
                width: "50",
                height: "5",
              },
            }),
            _c("rect", {
              attrs: {
                x: "70",
                y: "12",
                rx: "3",
                ry: "3",
                width: "100",
                height: "5",
              },
            }),
            _c("rect", {
              attrs: {
                x: "9",
                y: "31",
                rx: "3",
                ry: "3",
                width: "50",
                height: "5",
              },
            }),
            _c("rect", {
              attrs: {
                x: "70",
                y: "31",
                rx: "3",
                ry: "3",
                width: "100",
                height: "5",
              },
            }),
            _c("rect", {
              attrs: {
                x: "9",
                y: "51",
                rx: "3",
                ry: "3",
                width: "50",
                height: "5",
              },
            }),
            _c("rect", {
              attrs: {
                x: "70",
                y: "51",
                rx: "3",
                ry: "3",
                width: "100",
                height: "5",
              },
            }),
            _c("rect", {
              attrs: {
                x: "9",
                y: "71",
                rx: "3",
                ry: "3",
                width: "50",
                height: "5",
              },
            }),
            _c("rect", {
              attrs: {
                x: "70",
                y: "71",
                rx: "3",
                ry: "3",
                width: "100",
                height: "5",
              },
            }),
            _c("rect", {
              attrs: {
                x: "9",
                y: "91",
                rx: "3",
                ry: "3",
                width: "50",
                height: "5",
              },
            }),
            _c("rect", {
              attrs: {
                x: "70",
                y: "91",
                rx: "3",
                ry: "3",
                width: "100",
                height: "5",
              },
            }),
          ])
        : _vm._e(),
      _vm.type === "voting"
        ? _c("content-loader", { attrs: { height: 200, width: 400 } }, [
            _c("rect", {
              attrs: {
                x: "50",
                y: "9.61",
                rx: "3",
                ry: "3",
                width: "40",
                height: "200",
              },
            }),
            _c("rect", {
              attrs: {
                x: "130",
                y: "9.61",
                rx: "3",
                ry: "3",
                width: "40",
                height: "200",
              },
            }),
            _c("rect", {
              attrs: {
                x: "210",
                y: "7.61",
                rx: "3",
                ry: "3",
                width: "40",
                height: "200",
              },
            }),
            _c("rect", {
              attrs: {
                x: "290",
                y: "7.61",
                rx: "3",
                ry: "3",
                width: "40",
                height: "200",
              },
            }),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }