var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-5 border rounded update-profile" },
    [
      _c("div", { staticClass: "head-area" }, [
        _c("h2", [_vm._v(_vm._s(_vm.$t("edit-profile.heading")))]),
        _c("p", [_vm._v(_vm._s(_vm.$t("edit-profile.body")))]),
      ]),
      !_vm.userProfile && !_vm.error
        ? _c("ContentLoading", { attrs: { type: "profile" } })
        : _vm._e(),
      _vm.userProfile
        ? _c("ValidationObserver", {
            ref: "form",
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var passes = ref.passes
                    var invalid = ref.invalid
                    return [
                      _vm.success === false || _vm.success === null
                        ? _c(
                            "b-form",
                            {
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return passes(_vm.submitForm)
                                },
                              },
                            },
                            [
                              _c("ProfileForm", {
                                attrs: { formData: _vm.profileForm },
                                on: { update: _vm.updateProfileForm },
                              }),
                              _vm.error
                                ? _c("div", { staticClass: "text-danger" }, [
                                    _vm._v(" " + _vm._s(_vm.error) + " "),
                                  ])
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "col-sm-12 col-md-4 m-auto" },
                                [
                                  _c("Button", {
                                    staticClass: "w-100 green",
                                    attrs: {
                                      disabled: invalid,
                                      value: _vm.$t("edit-profile.save-button"),
                                    },
                                    on: {
                                      clicked: function ($event) {
                                        return _vm.submitForm()
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              false,
              2566018801
            ),
          })
        : _vm._e(),
      _vm.success === true
        ? _c("div", { attrs: { id: "sign-up-form-success" } }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("sign-up.success-message")))]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }