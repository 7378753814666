var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "centerbox pt-3 m-auto" },
    [
      _c(
        "div",
        { staticClass: "mb-2 d-flex flex-row-reverse action-btn" },
        [
          _c(
            "b-button-group",
            [
              _vm.role
                ? _c(
                    "b-button",
                    {
                      attrs: {
                        to: { name: "create-poll" },
                        variant: "btn btn-primary",
                      },
                    },
                    [
                      _c("BIconPlusCircle", { attrs: { scale: "1" } }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("poll.forms.new-poll-heading")) +
                          " "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._l(_vm.polls, function (item) {
        return _c(
          "div",
          { key: item._id, staticClass: "pollslist-box" },
          [
            _c(
              "b-card",
              { attrs: { tag: "article" } },
              [
                _c("b-card-body", [
                  _c(
                    "h3",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "poll",
                              params: { slug: item.info.slug },
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.info.caption) + " ")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "b-card-footer",
                  [
                    _c("div", [
                      _c(
                        "span",
                        [
                          _c("BIconCalendarRange", { attrs: { scale: "1" } }),
                          _c("Date", {
                            attrs: {
                              date: item.info.date,
                              format: "dynamicDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        [
                          _c("BIconPersonCircle", { attrs: { scale: "1" } }),
                          _c("ProfileLink", {
                            attrs: { profile: item.info.author },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        [
                          _c("BIconStarFill", { attrs: { scale: "1" } }),
                          _vm._v(" " + _vm._s(item.votes.total) + " "),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        [
                          _c("BIconChatTextFill", { attrs: { scale: "1" } }),
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "poll",
                                  params: { slug: item.info.slug },
                                  hash: "#comments",
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.comments.count) + " ")]
                          ),
                        ],
                        1
                      ),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("generic.content.state." + item.info.state)
                            ) +
                            " "
                        ),
                      ]),
                      item.info.state === "draft"
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("generic.content.state.draft")) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c(
                      "b-button-group",
                      [
                        _vm.role
                          ? _c(
                              "b-button",
                              {
                                attrs: {
                                  to: {
                                    name: "edit-poll",
                                    params: { slug: item.info.slug },
                                  },
                                  variant: "outline-primary",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  [
                                    _c("BIconPencilSquare", {
                                      attrs: { scale: "1" },
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("generic.edit-button")) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.role
                          ? _c(
                              "b-button",
                              {
                                attrs: { variant: "outline-primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.confirmDelete(item)
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  [
                                    _c("BIconTrash", { attrs: { scale: "1" } }),
                                    _vm._v(
                                      _vm._s(_vm.$t("generic.delete-button")) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }