var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    {
      staticClass: "title",
      attrs: { label: _vm.$t("generic.select-picture-label") },
    },
    [
      _c("b-img", {
        staticClass: "item-thumb",
        attrs: { thumbnail: "", src: _vm.currentPath },
        on: { click: _vm.showModal },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            id: "thumbs-list",
            title: _vm.$t("generic.select-picture-title"),
            size: "xl",
            centered: "",
            scrollable: "",
            "hide-footer": true,
          },
          model: {
            value: _vm.showSelector,
            callback: function ($$v) {
              _vm.showSelector = $$v
            },
            expression: "showSelector",
          },
        },
        _vm._l(_vm.pictures, function (picture) {
          return _c("b-img", {
            key: picture.path,
            staticClass: "item-thumb",
            attrs: { thumbnail: "", fluid: "", src: picture.path },
            on: {
              click: function ($event) {
                return _vm.selectPicture(picture.path)
              },
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }