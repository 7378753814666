var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-5 pt-3 pb-5 border centerbox" },
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("h1", [_vm._v(_vm._s(_vm.$t("generic.not-found-caption")))]),
            _c("p", [_vm._v(_vm._s(_vm.$t("generic.not-found-message")))]),
            _c("img", {
              attrs: {
                src: "/images/opravit.jpg",
                alt: _vm.$t("generic.we-must-fix"),
                width: "1156",
                height: "774",
              },
            }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }