var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.summary
    ? _c("div", { staticClass: "container mb-3" }, [
        _c("div", { staticClass: "row py-2" }, [
          _c(
            "div",
            { staticClass: "col col-md-3 m-auto" },
            [
              _c("Date", {
                attrs: { date: _vm.summary.date, format: "dynamicDate" },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "col col-md-6 m-auto" }, [
            _c("div", { staticClass: "d-flex justify-content-around" }, [
              _c("div", [
                _c("img", {
                  attrs: {
                    src: "/images/icons/crash.png",
                    width: "48",
                    height: "48",
                    alt: "Počet nehod",
                  },
                }),
                _c("span", { staticClass: "text-warning font-num" }, [
                  _c("span", { staticClass: "mx-2" }, [_vm._v("+")]),
                  _vm._v(_vm._s(_vm.summary.total.count)),
                ]),
              ]),
              _c("div", [
                _c("img", {
                  attrs: {
                    src: "/images/icons/tombstone.png",
                    width: "48",
                    height: "48",
                    alt: "Počet úmrtí",
                  },
                }),
                _c("span", { staticClass: "text-warning font-num" }, [
                  _c("span", { staticClass: "mx-2" }, [_vm._v(" +")]),
                  _vm._v(_vm._s(_vm.summary.total.impact.deaths)),
                ]),
              ]),
              _c("div", [
                _c("img", {
                  attrs: {
                    src: "/images/icons/ambulance.png",
                    width: "48",
                    height: "48",
                    alt: "Počet zranění",
                  },
                }),
                _c("span", { staticClass: "text-warning font-num" }, [
                  _c("span", { staticClass: "mx-2" }, [_vm._v("+")]),
                  _vm._v(
                    _vm._s(
                      _vm.summary.total.impact.severely +
                        _vm.summary.total.impact.slightly
                    )
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col col-md-3 m-auto" }, [
            _c(
              "div",
              { staticClass: "float-right" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "accidents-details",
                        params: { day: _vm.isoDate },
                      },
                    },
                  },
                  [
                    _vm._v("Více statistik "),
                    _c("BIconArrowRight", {
                      attrs: { scale: "1", variant: "dark" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }