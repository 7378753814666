var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "analyze-vote pt-3 mb-5" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col" },
        [
          !_vm.item
            ? _c("ContentLoading", { attrs: { type: "poll" } })
            : _vm._e(),
          _vm.item
            ? [
                _c("PollHeading", { attrs: { item: _vm.item } }),
                !_vm.inProgress
                  ? _c(
                      "div",
                      [
                        _c("SeriesBarChart", {
                          attrs: {
                            series: _vm.groups,
                            captions: _vm.captions,
                            "absolute-values": _vm.absoluteValues,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("PredefinedComparisons", { attrs: { slug: _vm.slug } }),
                _c(
                  "div",
                  { staticClass: "wrap-analyze-showcase" },
                  [
                    _c(
                      "b-form-group",
                      {
                        staticClass: "labelssss",
                        attrs: {
                          label: this.$t("poll.analysis.display_label"),
                          "label-cols": "1",
                        },
                      },
                      [
                        _c(
                          "b-form-radio-group",
                          {
                            staticClass: "showcase",
                            attrs: { id: "radio-group-2" },
                            model: {
                              value: _vm.absoluteValues,
                              callback: function ($$v) {
                                _vm.absoluteValues = $$v
                              },
                              expression: "absoluteValues",
                            },
                          },
                          [
                            _c("b-form-radio", { attrs: { value: true } }, [
                              _vm._v(_vm._s(_vm.$t("poll.analysis.values"))),
                            ]),
                            _c("b-form-radio", { attrs: { value: false } }, [
                              _vm._v(_vm._s(_vm.$t("poll.analysis.percents"))),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]),
    this.type === "vlastni"
      ? _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm-12 col-md-6 col-lg-6 campbox-one" },
            [
              _c(
                "b-card",
                { attrs: { header: _vm.captions[0] } },
                [_c("SeriesForm", { attrs: { group: _vm.forms[0], id: "1" } })],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-sm-12 col-md-6 col-lg-6 campbox-two" },
            [
              _c(
                "b-card",
                { attrs: { header: _vm.captions[1] } },
                [_c("SeriesForm", { attrs: { group: _vm.forms[1], id: "2" } })],
                1
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
    this.type === "vlastni"
      ? _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col text-center p-4" },
            [
              _c("b-button", { on: { click: _vm.handleCustom } }, [
                _vm._v(_vm._s(_vm.$t("poll.analysis.button"))),
              ]),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }