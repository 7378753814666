var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    {
      staticClass: "title",
      attrs: { label: _vm.$t("generic.tag-select-label") },
    },
    [
      _c("b-form-tags", {
        staticClass: "mb-0",
        attrs: { size: "lg", "add-on-change": "", "no-outer-focus": "" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var tags = ref.tags
              var inputAttrs = ref.inputAttrs
              var inputHandlers = ref.inputHandlers
              var disabled = ref.disabled
              var removeTag = ref.removeTag
              return [
                tags.length > 0
                  ? _c(
                      "ul",
                      {
                        staticClass: "list-inline d-inline-block mb-2 tagslist",
                      },
                      _vm._l(tags, function (tag) {
                        return _c(
                          "li",
                          { key: tag, staticClass: "list-inline-item" },
                          [
                            _c(
                              "b-form-tag",
                              {
                                attrs: {
                                  title: tag,
                                  disabled: disabled,
                                  variant: "info",
                                },
                                on: {
                                  remove: function ($event) {
                                    return removeTag(tag)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(tag) + " ")]
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _c(
                  "b-form-select",
                  _vm._g(
                    _vm._b(
                      {
                        attrs: {
                          disabled:
                            disabled || _vm.availableOptions.length === 0,
                          options: _vm.availableOptions,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "first",
                              fn: function () {
                                return [
                                  _c(
                                    "option",
                                    { attrs: { disabled: "", value: "" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "generic.tag-select-placeholder"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      "b-form-select",
                      inputAttrs,
                      false
                    ),
                    inputHandlers
                  )
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tagList,
          callback: function ($$v) {
            _vm.tagList = $$v
          },
          expression: "tagList",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }