var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "poll-header" },
      [
        _vm.item.siblings
          ? _c(
              "b-col",
              { staticClass: "prevbtn" },
              [
                _vm.item.siblings.older
                  ? _c(
                      "b-button",
                      {
                        attrs: {
                          to: _vm.link(_vm.item.siblings.older),
                          variant: "secondary",
                        },
                      },
                      [
                        _c("BIconChevronDoubleLeft", {
                          attrs: { "font-scale": "2" },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _c("b-col", { staticClass: "center-box" }, [
          _c(
            "h4",
            { staticClass: "pollheading" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: { name: "poll", params: { slug: _vm.item.info.slug } },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.item.info.caption) + " ")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "item-div item-hover mb-4" }, [
            _c("div", { staticClass: "item-footer" }, [
              _c("div", { staticClass: "post-details" }, [
                _c(
                  "div",
                  { staticClass: "post-author" },
                  [
                    _c("BIconPersonCircle", { attrs: { scale: "1" } }),
                    _c(
                      "span",
                      [
                        _c("ProfileLink", {
                          attrs: { profile: this.item.info.author },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "post-time" },
                  [
                    _c("BIconCalendarRange", { attrs: { scale: "1" } }),
                    _c(
                      "span",
                      [
                        _c("Date", {
                          attrs: {
                            date: this.item.info.date,
                            format: "dynamicDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "post-rating" },
                  [
                    _c("BIconCollection", { attrs: { scale: "1" } }),
                    _c("span", [_vm._v(" " + _vm._s(_vm.item.votes.total))]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "post-comments" },
                  [
                    _c("BIconChatTextFill", { attrs: { scale: "1" } }),
                    _c("router-link", { attrs: { to: "#comments" } }, [
                      _c("span", [_vm._v(_vm._s(_vm.item.comments.count))]),
                      _vm.item.comments.count > 0
                        ? _c(
                            "span",
                            [
                              _vm._v("  "),
                              _c("Date", {
                                attrs: {
                                  date: _vm.item.comments.last,
                                  format: "dynamicDateTime",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
                _vm.hasTags
                  ? _c(
                      "div",
                      { staticClass: "post-tags" },
                      [
                        _c("BIconTags", { attrs: { scale: "1" } }),
                        _vm._l(_vm.tags, function (tag) {
                          return _c(
                            "router-link",
                            {
                              key: tag,
                              attrs: {
                                to: { name: "tag", params: { tag: tag } },
                              },
                            },
                            [_vm._v(" #" + _vm._s(tag) + " ")]
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]),
        _vm.item.siblings
          ? _c(
              "b-col",
              { staticClass: "nextbtn" },
              [
                _vm.item.siblings.newer
                  ? _c(
                      "b-button",
                      {
                        attrs: {
                          to: _vm.link(_vm.item.siblings.newer),
                          variant: "secondary",
                        },
                      },
                      [
                        _c("BIconChevronDoubleRight", {
                          attrs: { "font-scale": "2" },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }