var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-3 centerbox m-auto" },
    [
      _c("div", { staticClass: "head-area" }, [
        _c("h2", [_vm._v(_vm._s(_vm.$t("page-title.articles")))]),
      ]),
      !_vm.isAuthorized
        ? _c("div", [
            _vm._v(" " + _vm._s(_vm.$t("articles.not-authorized")) + " "),
          ])
        : [
            !_vm.articles
              ? _c("ContentLoading", { attrs: { type: "items" } })
              : _vm._e(),
            _vm.noArticlesFound
              ? _c("div", [_vm._v(_vm._s(_vm.$t("articles.empty")))])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "mb-2 d-flex flex-row-reverse action-btn" },
              [
                _c(
                  "b-button-group",
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          to: { name: "create-article" },
                          variant: "outline-primary",
                        },
                      },
                      [
                        _c("BIconFileEarmarkPlusFill", {
                          attrs: { scale: "1" },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("articles.new-article-button")) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._l(_vm.articles, function (item) {
              return _c(
                "div",
                { key: item._id, staticClass: "pagelist-box" },
                [
                  _c(
                    "b-card",
                    { attrs: { tag: "article" } },
                    [
                      _c("b-card-body", [
                        _c(
                          "h3",
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "article",
                                    params: { slug: item.info.slug },
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(item.info.caption) + " ")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "b-card-footer",
                        [
                          _c("div", [
                            _c(
                              "span",
                              [
                                _vm.isWaiting(item)
                                  ? _c("BIconCalendarCheck", {
                                      attrs: { scale: "1" },
                                    })
                                  : _c("BIconClock", { attrs: { scale: "1" } }),
                                _c("Date", {
                                  attrs: {
                                    date: item.info.date,
                                    format: "dynamicDate",
                                  },
                                }),
                                item.info.state === "draft"
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "generic.content.state.draft"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.isWaiting(item)
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("articles.scheduled-article")
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "span",
                              [
                                _c("BIconPersonCircle", {
                                  attrs: { scale: "1" },
                                }),
                                _c("ProfileLink", {
                                  attrs: { profile: item.info.author },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "b-button-group",
                            [
                              _vm.canEdit(item)
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        to: {
                                          name: "update-article",
                                          params: { slug: item.info.slug },
                                        },
                                        variant: "outline-primary",
                                      },
                                    },
                                    [
                                      _c("BIconPencilSquare", {
                                        attrs: { scale: "1" },
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("generic.edit-button")
                                          ) +
                                          " "
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isEditor
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        to: {
                                          name: "code-article",
                                          params: { slug: item.info.slug },
                                        },
                                        variant: "outline-primary",
                                      },
                                    },
                                    [
                                      _c("BIconCodeSlash", {
                                        attrs: { scale: "1" },
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("generic.edit-html-button")
                                          ) +
                                          " "
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.info.state === "draft"
                                ? _c(
                                    "b-button",
                                    {
                                      staticStyle: { "margin-right": "10px" },
                                      attrs: { variant: "outline-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.togglePublished(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("BIconToggle2Off", {
                                        attrs: { scale: "1" },
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("articles.publish.mark")
                                          ) +
                                          " "
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "b-button",
                                    {
                                      staticStyle: { "margin-right": "10px" },
                                      attrs: { variant: "outline-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.togglePublished(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("BIconToggle2On", {
                                        attrs: { scale: "1" },
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("articles.publish.unmark")
                                          ) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                              _vm.canDelete(item)
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "outline-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.confirmDelete(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("BIconTrash", {
                                        attrs: { scale: "1" },
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("generic.delete-button")
                                          ) +
                                          " "
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }