var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-4 centerbox" },
    [
      _c("ValidationObserver", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var invalid = ref.invalid
              return [
                _c("div", { staticClass: "container" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col text-area" },
                      [
                        _c("TextInput", {
                          staticClass: "write-blog",
                          attrs: {
                            name: "title",
                            placeholder: _vm.$t("blog.form.title-placeholder"),
                            "aria-describedby": "title-errors",
                            rules: { required: true },
                          },
                          model: {
                            value: _vm.title,
                            callback: function ($$v) {
                              _vm.title = $$v
                            },
                            expression: "title",
                          },
                        }),
                        _c("Editor", {
                          class: { invalid: !_vm.hideContentError },
                          attrs: {
                            blog: _vm.blog,
                            "aria-describedby": "content-errors",
                          },
                          on: { outOfFocus: _vm.handleOutOfFocus },
                          model: {
                            value: _vm.html,
                            callback: function ($$v) {
                              _vm.html = $$v
                            },
                            expression: "html",
                          },
                        }),
                        _c(
                          "b-form-invalid-feedback",
                          {
                            attrs: {
                              id: "content-errors",
                              state: _vm.hideContentError,
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("blog.form.content-error")))]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "col col-lg-2 sidebar" }, [
                      _c(
                        "div",
                        { staticClass: "tags-area" },
                        [
                          _c("TagSelector", {
                            attrs: { formTags: _vm.tags },
                            on: { changeTags: _vm.tagSelect },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "image-area" },
                        [
                          _c("SelectPicture", {
                            attrs: { currentPath: _vm.picture },
                            on: { changePath: _vm.changePath },
                          }),
                          _c(
                            "div",
                            { staticClass: "errors" },
                            [
                              _c(
                                "b-alert",
                                {
                                  staticClass: "p-0",
                                  attrs: {
                                    variant: "danger",
                                    dismissible: "",
                                    show: _vm.errors.length > 0,
                                  },
                                },
                                [
                                  _c(
                                    "ul",
                                    _vm._l(_vm.errors, function (error) {
                                      return _c("li", { key: error.message }, [
                                        _vm._v(_vm._s(error)),
                                      ])
                                    }),
                                    0
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                variant: "post-btn",
                                disabled: invalid || _vm.isEmpty,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.saveBlog()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("generic.save-button")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }