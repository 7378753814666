var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "single-post-wrap pt-3 ml-auto mr-auto mt-auto mb-5" },
    [
      _c(
        "div",
        { staticClass: "poll-content" },
        [
          !_vm.poll
            ? _c("ContentLoading", { attrs: { type: "poll" } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "completed-poll-wrap" },
        [_vm.poll ? _c("CompletePoll") : _vm._e()],
        1
      ),
      _c("ShareLink", { attrs: { item: _vm.poll } }),
      _c(
        "div",
        { staticClass: "comment-outer" },
        [
          _vm.poll
            ? _c("Comments", { attrs: { itemId: _vm.poll._id } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }