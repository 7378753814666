var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "inside" },
    [
      _vm.horizontal
        ? _c("bar-chart", {
            attrs: {
              data: _vm.chartData,
              colors: [_vm.colors],
              suffix: "%",
              height: "100px",
            },
          })
        : _vm._e(),
      !_vm.horizontal
        ? _c("column-chart", {
            attrs: {
              data: _vm.chartData,
              colors: [_vm.colors],
              suffix: "%",
              height: "200px",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }