var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.article._id
    ? _c("div", { staticClass: "blog-posts pt-3 m-auto" }, [
        _c("div", { staticClass: "post-details-wrap" }, [
          _c("div", [
            _c("div", { staticClass: "hero-details" }, [
              _c("h1", [_vm._v(_vm._s(_vm.title))]),
            ]),
            _c("div", { staticClass: "post-details" }, [
              _c(
                "div",
                { staticClass: "post-author" },
                [
                  _c("BIconPersonCircle", { attrs: { scale: "1" } }),
                  _c("ProfileLink", {
                    attrs: { profile: _vm.article.info.author },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "post-time" },
                [
                  _vm.isWaiting(_vm.article)
                    ? _c("BIconCalendarCheck", { attrs: { scale: "1" } })
                    : _c("BIconClock", { attrs: { scale: "1" } }),
                  _c("Date", {
                    attrs: {
                      date: _vm.article.info.date,
                      format: "dynamicDate",
                    },
                  }),
                  _vm.article.info.state === "draft"
                    ? _c("span", { staticStyle: { "margin-left": "5px" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("generic.content.state.draft")) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  _vm.isWaiting(_vm.article)
                    ? _c("span", { staticStyle: { "margin-left": "5px" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("articles.scheduled-article")) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "post-comments" },
                [
                  _c("BIconChatTextFill", { attrs: { scale: "1" } }),
                  _c("b-link", { on: { click: _vm.toComments } }, [
                    _vm._v(" " + _vm._s(_vm.article.comments.count) + " "),
                  ]),
                ],
                1
              ),
              _vm.canEdit
                ? _c(
                    "div",
                    { staticClass: "post-edit" },
                    [
                      _c("BIconPencilSquare", { attrs: { scale: "1" } }),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "update-article",
                              params: { id: _vm.article._id },
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("generic.edit-button")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "errors" },
              [
                _c(
                  "b-alert",
                  {
                    attrs: {
                      variant: "danger",
                      dismissible: "",
                      show: _vm.error !== undefined,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.error) + " ")]
                ),
              ],
              1
            ),
            _c("div", {
              staticClass: "post-content p3",
              domProps: { innerHTML: _vm._s(_vm.generatedHtml) },
            }),
            _c(
              "div",
              { staticClass: "content-wrap" },
              [
                _c("b-alert", { attrs: { show: "", variant: "primary" } }, [
                  _vm._v(
                    " Článek vás zřejmě zaujal, když jste jej dočetli až do konce. Pomůžete nám? Potřebujeme další čtenáře. Doporučte server "
                  ),
                  _c("a", { attrs: { href: "/" } }, [
                    _vm._v("Mezi námi řidiči"),
                  ]),
                  _vm._v(" kamarádům, sdílejte "),
                  _c("a", { attrs: { href: "" } }, [_vm._v("tento článek")]),
                  _vm._v(", přidejte si "),
                  _c("a", { attrs: { href: "/feed.rss" } }, [
                    _vm._v("naše RSS do čtečky"),
                  ]),
                  _vm._v(
                    ". Máte-li webové stránky, vložte do nich odkaz na náš server "
                  ),
                  _c("a", { attrs: { href: "/" } }, [
                    _vm._v("Mezi námi řidiči"),
                  ]),
                  _vm._v(". Předem děkujeme. "),
                ]),
                _c("ShareLink", { attrs: { item: _vm.article } }),
                _c("Comments", { attrs: { itemId: _vm.article._id } }),
              ],
              1
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }