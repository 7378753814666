import { render, staticRenderFns } from "./Pages.vue?vue&type=template&id=2aa7b742&scoped=true&"
import script from "./Pages.vue?vue&type=script&lang=js&"
export * from "./Pages.vue?vue&type=script&lang=js&"
import style0 from "./Pages.vue?vue&type=style&index=0&id=2aa7b742&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2aa7b742",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\dev\\mezinamiridici\\spa\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2aa7b742')) {
      api.createRecord('2aa7b742', component.options)
    } else {
      api.reload('2aa7b742', component.options)
    }
    module.hot.accept("./Pages.vue?vue&type=template&id=2aa7b742&scoped=true&", function () {
      api.rerender('2aa7b742', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/item/Pages.vue"
export default component.exports