var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-2 poll-form" },
    [
      _c("ValidationObserver", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var handleSubmit = ref.handleSubmit
              return [
                _c(
                  "b-form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return handleSubmit(_vm.onSubmit)
                      },
                    },
                  },
                  [
                    _c("ValidationProvider", {
                      attrs: { rules: { required: true, min: 10 } },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (validationContext) {
                              return [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "text-group",
                                      label: _vm.$t("poll.forms.text-label"),
                                      "label-for": "text",
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "text",
                                        name: "text",
                                        "aria-describedby": "text-errors",
                                        state:
                                          _vm.getValidationState(
                                            validationContext
                                          ),
                                      },
                                      model: {
                                        value: _vm.form.text,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "text", $$v)
                                        },
                                        expression: "form.text",
                                      },
                                    }),
                                    _c(
                                      "b-form-invalid-feedback",
                                      { attrs: { id: "text-errors" } },
                                      [
                                        _vm._v(
                                          _vm._s(validationContext.errors[0])
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _c("ValidationProvider", {
                      attrs: { rules: { required: true, min: 10 } },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (validationContext) {
                              return [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "date-group",
                                      label: _vm.$t("poll.forms.date-label"),
                                      "label-for": "date",
                                    },
                                  },
                                  [
                                    _c("b-form-datepicker", {
                                      attrs: {
                                        "date-format-options": {
                                          year: "numeric",
                                          month: "numeric",
                                          day: "numeric",
                                        },
                                        locale: "cs",
                                        "aria-describedby": "date-errors",
                                        state:
                                          _vm.getValidationState(
                                            validationContext
                                          ),
                                      },
                                      on: { context: _vm.onContext },
                                      model: {
                                        value: _vm.form.date,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "date", $$v)
                                        },
                                        expression: "form.date",
                                      },
                                    }),
                                    _c(
                                      "b-form-invalid-feedback",
                                      { attrs: { id: "date-errors" } },
                                      [
                                        _vm._v(
                                          _vm._s(validationContext.errors[0])
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _c("ValidationProvider", {
                      attrs: { rules: { required: false } },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (validationContext) {
                              return [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "author-id-group",
                                      label: _vm.$t(
                                        "poll.forms.author-id-label"
                                      ),
                                      "label-for": "authorId",
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "authorId",
                                        name: "authorId",
                                        "aria-describedby": "authorId-errors",
                                        state:
                                          _vm.getValidationState(
                                            validationContext
                                          ),
                                      },
                                      model: {
                                        value: _vm.form.authorId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "authorId", $$v)
                                        },
                                        expression: "form.authorId",
                                      },
                                    }),
                                    _c(
                                      "b-form-invalid-feedback",
                                      { attrs: { id: "authorId-errors" } },
                                      [
                                        _vm._v(
                                          _vm._s(validationContext.errors[0])
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _c("TagSelector", {
                      attrs: { formTags: _vm.form.pollTagsList },
                      on: { changeTags: _vm.tagSelect },
                    }),
                    _c("SelectPicture", {
                      attrs: { currentPath: _vm.form.picture },
                      on: { changePath: _vm.changePath },
                    }),
                    !_vm.isCreate
                      ? _c(
                          "b-form-checkbox",
                          {
                            staticClass: "mb-3",
                            attrs: {
                              id: "checkbox-published",
                              name: "published",
                            },
                            model: {
                              value: _vm.form.published,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "published", $$v)
                              },
                              expression: "form.published",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("poll.forms.published-label")) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.error
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(" " + _vm._s(_vm.error) + " "),
                        ])
                      : _vm._e(),
                    _c(
                      "b-button",
                      { attrs: { type: "submit", variant: "primary" } },
                      [_vm._v("Submit")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }