var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "p",
      [
        _vm._v(
          " Cílem stránek je prevence dopravních nehod skrze výměnu názorů a vzdělávání. "
        ),
        _c("router-link", { attrs: { to: "/o/mise" } }, [_vm._v("Naše mise")]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }