var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "comparison-box", attrs: { id: "scroll" } }, [
    _c("div", { staticClass: "comparison-list" }, [
      _c("h6", [_vm._v(_vm._s(_vm.$t("poll.analysis.comparison")))]),
      _c("ul", [
        _c(
          "li",
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "analyse-poll",
                    params: { slug: _vm.slug, type: "muzi_zeny" },
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("poll.analysis.men_women")) + " ")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "analyse-poll",
                    params: { slug: _vm.slug, type: "auto_kamion" },
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("poll.analysis.car_truck")) + " ")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "analyse-poll",
                    params: { slug: _vm.slug, type: "auto_motorka" },
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("poll.analysis.car_bike")) + " ")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "analyse-poll",
                    params: { slug: _vm.slug, type: "zajic_zkuseny" },
                  },
                },
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("poll.analysis.unseasoned_veteran")) + " "
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "analyse-poll",
                    params: { slug: _vm.slug, type: "praha_brno" },
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("poll.analysis.praha_brno")) + " ")]
            ),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "custombtn" },
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "analyse-poll",
                    params: { slug: _vm.slug, type: "vlastni" },
                  },
                },
              },
              [
                _vm._v(" " + _vm._s(_vm.$t("poll.analysis.custom")) + " "),
                _c("BIconGear", { attrs: { scale: "1" } }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }