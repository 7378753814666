var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form-radio",
        {
          ref: "radio",
          attrs: {
            type: "radio",
            id: _vm.identifier,
            value: _vm.identifier,
            name: _vm.name,
          },
          nativeOn: {
            click: function ($event) {
              return _vm.updateRadio()
            },
          },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v
            },
            expression: "selected",
          },
        },
        [_vm._v(" " + _vm._s(_vm.label) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }