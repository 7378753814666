var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationProvider", {
    attrs: {
      tag: "span",
      vid: _vm.vid,
      rules: _vm.rules,
      name: _vm.name || _vm.label,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function (ref) {
            var errors = ref.errors
            return [
              _c(
                "b-form-checkbox",
                {
                  ref: "input",
                  class: {
                    "border-gray-700": !errors[0],
                    "border-red-600": errors[0],
                  },
                  attrs: { id: _vm.identifier },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.selectfunction($event)
                    },
                  },
                  model: {
                    value: _vm.checked,
                    callback: function ($$v) {
                      _vm.checked = $$v
                    },
                    expression: "checked",
                  },
                },
                [
                  _vm.label
                    ? _c(
                        "label",
                        {
                          staticClass: "atoms__checkbox-label",
                          attrs: { for: _vm.identifier },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.label))])]
                      )
                    : _vm._e(),
                  _vm._t("default"),
                ],
                2
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v
      },
      expression: "value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }