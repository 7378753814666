var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-3 m-auto pb-5" },
    [
      !_vm.userProfile
        ? _c("ContentLoading", { attrs: { type: "profile" } })
        : _vm._e(),
      _vm.userProfile
        ? _c(
            "div",
            { staticClass: "profile-wrap" },
            [
              _c("div", { staticClass: "prof-header-wrap" }, [
                _c(
                  "div",
                  { staticClass: "prof-header" },
                  [
                    _c("b-avatar", { attrs: { size: "5rem" } }),
                    _c("div", { staticClass: "nickname-row" }, [
                      _c(
                        "h2",
                        [
                          _vm._v(_vm._s(_vm.userProfile.bio.nickname) + " "),
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "update-profile",
                                  params: { id: _vm.id },
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "edit-btn" },
                                [
                                  _c("BIconPencilSquare", {
                                    attrs: { scale: "1.6" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "user-details" }, [
                  _c(
                    "ul",
                    { staticClass: "links" },
                    _vm._l(_vm.userProfile.bio.urls, function (item, inx) {
                      return _c(
                        "li",
                        { key: inx },
                        [
                          _c("BIconLink"),
                          _c("a", { attrs: { href: item, rel: "nofollow" } }, [
                            _vm._v(_vm._s(item)),
                          ]),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _vm.userProfile.bio.about
                    ? _c("div", { staticClass: "user-bio" }, [
                        _c("p", [_vm._v(_vm._s(_vm.userProfile.bio.about))]),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c(
                "b-tabs",
                {
                  staticClass: "no-padding",
                  attrs: { "content-class": "my-3" },
                },
                [
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        title: _vm.$t("profile.tabs.summary"),
                        active: "",
                      },
                    },
                    [
                      _c("div", { staticClass: "profile-area-top" }, [
                        _c(
                          "div",
                          { staticClass: "rank head-block" },
                          [
                            _c("BIconAward", { attrs: { scale: "1" } }),
                            _c("span", { staticClass: "label" }, [
                              _vm._v(
                                _vm._s(_vm.$t("profile.rank-label")) + ":"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "profile.rank." +
                                        _vm.userProfile.honors.rank
                                    )
                                  )
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "bio-date head-block" },
                          [
                            _c("BIconPersonCircle", { attrs: { scale: "1" } }),
                            _c("span", { staticClass: "label" }, [
                              _vm._v(
                                _vm._s(_vm.$t("profile.member-since-label")) +
                                  ":"
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              [
                                _c("Date", {
                                  attrs: {
                                    date: _vm.userProfile.bio.registered,
                                    format: "dynamicDate",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm.showProfile
                        ? _c(
                            "div",
                            [
                              _vm.userProfile.driving
                                ? [
                                    _c("div", { staticClass: "more-details" }, [
                                      _vm.userProfile.driving.since
                                        ? _c(
                                            "div",
                                            { staticClass: "head-block" },
                                            [
                                              _c("BIconJoystick", {
                                                attrs: { scale: "1" },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "label" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "profile.driving-since-label"
                                                      )
                                                    ) + ":"
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.userProfile.driving
                                                        .since
                                                    )
                                                ),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.vehicles
                                        ? _c(
                                            "div",
                                            { staticClass: "head-block" },
                                            [
                                              _c("BIconTruck", {
                                                attrs: { scale: "1" },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "label" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "profile.vehicles-label"
                                                      )
                                                    ) + ":"
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  " " + _vm._s(_vm.vehicles)
                                                ),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]
                                : _vm._e(),
                              _c("div", { staticClass: "more-details" }, [
                                _vm.userProfile.bio.sex
                                  ? _c(
                                      "div",
                                      { staticClass: "head-block" },
                                      [
                                        _c("BIconPersonFill", {
                                          attrs: { scale: "1" },
                                        }),
                                        _c("span", { staticClass: "label" }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("profile.sex")) + ":"
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "profile.sexes." +
                                                    _vm.userProfile.bio.sex
                                                )
                                              )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.userProfile.bio.born
                                  ? _c(
                                      "div",
                                      { staticClass: "head-block" },
                                      [
                                        _c("BIconPersonCheck", {
                                          attrs: { scale: "1" },
                                        }),
                                        _c("span", { staticClass: "label" }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("profile.born")) + ":"
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.userProfile.bio.born)
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.userProfile.bio.region
                                  ? _c(
                                      "div",
                                      { staticClass: "head-block" },
                                      [
                                        _c("BIconGeoAlt", {
                                          attrs: { "font-scale": "1" },
                                        }),
                                        _c("span", { staticClass: "label" }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("profile.region")) +
                                              ":"
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "profile.regions." +
                                                    _vm.userProfile.bio.region
                                                )
                                              )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.userProfile.bio.edu
                                  ? _c(
                                      "div",
                                      { staticClass: "head-block" },
                                      [
                                        _c("BIconPen", {
                                          attrs: { scale: "1" },
                                        }),
                                        _c("span", { staticClass: "label" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("profile.education")
                                            ) + ":"
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "profile.educations." +
                                                    _vm.userProfile.bio.edu
                                                )
                                              )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "b-tab",
                    { attrs: { title: _vm.$t("profile.tabs.activity") } },
                    [
                      _c("UserActivity", {
                        attrs: { userId: _vm.userProfile._id },
                      }),
                    ],
                    1
                  ),
                  _vm.myProfile && _vm.userProfile.honors.rank !== "expert"
                    ? _c(
                        "b-tab",
                        { attrs: { title: _vm.$t("profile.tabs.honors") } },
                        [
                          _vm.myProfile
                            ? _c("HonorsProgress", {
                                attrs: { user: _vm.userProfile },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.myProfile
                    ? _c(
                        "b-tab",
                        { attrs: { title: _vm.$t("profile.tabs.settings") } },
                        [
                          _c("ChangePassword", {
                            attrs: { email: _vm.userProfile.auth.email },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }