var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading && _vm.list.length === 0
        ? _c("ContentLoading", { attrs: { type: "items" } })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "home-posts" },
        _vm._l(_vm.list, function (item) {
          return _c(
            "div",
            { key: item._id, staticClass: "item m-2" },
            [_c("ViewItem", { attrs: { item: item } })],
            1
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "text-center my-4" },
        [
          !_vm.endOfData && _vm.list.length > 0
            ? _c("ButtonOutlined", {
                attrs: {
                  waiting: _vm.loading,
                  value: _vm.$t("generic.load-next-button"),
                  sm: "sm",
                },
                on: {
                  clicked: function ($event) {
                    return _vm.loadNextPage()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }