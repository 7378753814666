var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "complete" },
    [
      _c("PollHeading", { attrs: { item: _vm.item } }),
      _vm.voted
        ? _c("div", { staticClass: "pt-2 pb-2 chart-wrap" }, [
            _c(
              "div",
              { staticClass: "vote-chart" },
              [
                _c("VotesChart", {
                  attrs: { votes: _vm.item.votes, voted: _vm.voted },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "comparision-wrap" },
              [
                _c("PredefinedComparisons", {
                  attrs: { slug: _vm.item.info.slug },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      !_vm.voted
        ? _c(
            "div",
            { staticClass: "m-auto pt-0 pb-3" },
            [_c("PollButtons", { on: { "do-vote": _vm.onVote } })],
            1
          )
        : _vm._e(),
      _vm.voting
        ? _c("ContentLoading", { attrs: { type: "voting" } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }