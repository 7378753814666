var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.infoBoxContent
    ? _c(
        "div",
        { staticClass: "info-box" },
        [
          _c(
            "span",
            {
              staticClass: "closebtn",
              on: {
                click: function ($event) {
                  return _vm.ignoreMessage()
                },
              },
            },
            [_c("BIconXCircle", { attrs: { scale: "1" } })],
            1
          ),
          _c(_vm.infoBoxContent, { tag: "component" }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }