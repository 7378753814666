var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-3 mx-3" },
    [
      _c(
        "h1",
        [
          _vm._v(" Statistiky nehod pro " + _vm._s(_vm.formattedDate) + " "),
          _c(
            "b-button",
            {
              attrs: { size: "sm", variant: "link" },
              on: { click: _vm.openPicker },
            },
            [_c("BIconCalendarDate", { attrs: { scale: "1.3" } })],
            1
          ),
        ],
        1
      ),
      _c("datepicker", {
        ref: "programaticOpen",
        attrs: {
          minimumView: "day",
          "disabled-dates": _vm.statsScope,
          name: "daySelector",
          language: _vm.csLocale,
          "monday-first": true,
          "bootstrap-styling": true,
          "input-class": "hide-input",
        },
        model: {
          value: _vm.selectedDay,
          callback: function ($$v) {
            _vm.selectedDay = $$v
          },
          expression: "selectedDay",
        },
      }),
      _vm.inProgress
        ? _c(
            "content-loader",
            { attrs: { height: 820, width: 600, speed: 8 } },
            [
              _c("rect", {
                attrs: {
                  x: "0",
                  y: "0",
                  rx: "3",
                  ry: "3",
                  width: "600",
                  height: "800",
                },
              }),
            ]
          )
        : _vm._e(),
      !_vm.inProgress && _vm.thisDay
        ? _c("div", { staticClass: "blog-posts pt-3 m-auto" }, [
            _c("p", [
              _vm._v(
                " Tento den se stalo na českých silnicích " +
                  _vm._s(_vm.thisDay.total.count) +
                  " dopravních nehod, při kterých "
              ),
              _vm.thisDay.total.impact.deaths === 0
                ? _c("span", [_vm._v("naštěstí nikdo nezahynul.")])
                : _vm.thisDay.total.impact.deaths === 1
                ? _c("span", [_vm._v("bohužel zemřel jeden člověk.")])
                : _vm.thisDay.total.impact.deaths < 5
                ? _c("span", [
                    _vm._v(
                      "bohužel zemřeli " +
                        _vm._s(_vm.thisDay.total.impact.deaths) +
                        " lidé."
                    ),
                  ])
                : _c("span", [
                    _vm._v(
                      "bohužel zemřelo " +
                        _vm._s(_vm.thisDay.total.impact.deaths) +
                        " lidí."
                    ),
                  ]),
              _vm._v(" Záchranáři ošetřili "),
              _vm.thisDay.total.impact.severely === 1
                ? _c("span", [_vm._v("jednoho těžce zraněného člověka a ")])
                : _vm.thisDay.total.impact.severely > 1 &&
                  _vm.thisDay.total.impact.severely < 5
                ? _c("span", [
                    _vm._v(
                      _vm._s(_vm.thisDay.total.impact.severely) +
                        " těžce zraněné a "
                    ),
                  ])
                : _vm.thisDay.total.impact.severely >= 5
                ? _c("span", [
                    _vm._v(
                      _vm._s(_vm.thisDay.total.impact.severely) +
                        " těžce zraněných a "
                    ),
                  ])
                : _vm._e(),
              _vm._v(
                " " +
                  _vm._s(_vm.thisDay.total.impact.slightly) +
                  " lehce zraněných lidí. Dopravní policisté stanovili příčiny nehod takto: "
              ),
              _vm.thisDay.total.reason.speed > 0
                ? _c("span", [
                    _vm._v(
                      _vm._s(_vm.thisDay.total.reason.speed) +
                        "× nepřiměřená rychlost, "
                    ),
                  ])
                : _vm._e(),
              _vm.thisDay.total.reason.giveway > 0
                ? _c("span", [
                    _vm._v(
                      _vm._s(_vm.thisDay.total.reason.giveway) +
                        "× nedání přednosti v jízdě, "
                    ),
                  ])
                : _vm._e(),
              _vm.thisDay.total.reason.passing > 0
                ? _c("span", [
                    _vm._v(
                      _vm._s(_vm.thisDay.total.reason.passing) +
                        "× nesprávné předjíždění, "
                    ),
                  ])
                : _vm._e(),
              _vm.thisDay.total.reason.drunk > 0
                ? _c("span", [
                    _vm._v(
                      _vm._s(_vm.thisDay.total.reason.drunk) +
                        "× řízení pod vlivem návykových látek, "
                    ),
                  ])
                : _vm._e(),
              _vm._v(
                " " +
                  _vm._s(_vm.thisDay.total.reason.mistake) +
                  "× nesprávný způsob jízdy a " +
                  _vm._s(_vm.thisDay.total.reason.other) +
                  "× jiná příčina. "
              ),
            ]),
            _c("div", { staticClass: "stats-wrap" }, [
              _c("div", { staticClass: "stats-result table-responsive" }, [
                _c(
                  "table",
                  {
                    staticClass:
                      "table table-hover table-sm table-bordered text-center",
                  },
                  [
                    _vm._m(0),
                    _c(
                      "tbody",
                      [
                        _vm._l(_vm.thisDay.regions, function (region) {
                          return _c("tr", { key: region.region }, [
                            _c("td", [
                              _vm._v(_vm._s(_vm.REGIONS[region.region])),
                            ]),
                            _c("td", [_vm._v(_vm._s(region.count))]),
                            _c("td", [_vm._v(_vm._s(region.impact.deaths))]),
                            _c("td", [_vm._v(_vm._s(region.impact.severely))]),
                            _c("td", [_vm._v(_vm._s(region.impact.slightly))]),
                            _c("td", [_vm._v(_vm._s(region.reason.speed))]),
                            _c("td", [_vm._v(_vm._s(region.reason.giveway))]),
                            _c("td", [_vm._v(_vm._s(region.reason.passing))]),
                            _c("td", [_vm._v(_vm._s(region.reason.drunk))]),
                            _c("td", [_vm._v(_vm._s(region.reason.mistake))]),
                            _c("td", [_vm._v(_vm._s(region.reason.other))]),
                          ])
                        }),
                        _c("tr", [
                          _c("td", [_vm._v("Celkem")]),
                          _c("td", [_vm._v(_vm._s(_vm.thisDay.total.count))]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.thisDay.total.impact.deaths)),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.thisDay.total.impact.severely)),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.thisDay.total.impact.slightly)),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.thisDay.total.reason.speed)),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.thisDay.total.reason.giveway)),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.thisDay.total.reason.passing)),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.thisDay.total.reason.drunk)),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.thisDay.total.reason.mistake)),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.thisDay.total.reason.other)),
                          ]),
                        ]),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
              _vm.lastYear
                ? _c("h2", [_vm._v("Srovnání s předchozím rokem")])
                : _vm._e(),
              _c("div", { staticClass: "my-2" }, [
                _vm._v(" Počet úmrtí se do " + _vm._s(_vm.numericDate) + " "),
                _vm.causaltiesDiff === 0
                  ? _c("span", [_vm._v("nezměnil")])
                  : _vm.causaltiesDiff === -1
                  ? _c("span", [_vm._v("snížil o jednu oběť")])
                  : _vm.causaltiesDiff === 1
                  ? _c("span", [_vm._v("zvýšil o jednu oběť")])
                  : _vm.causaltiesDiff <= -2 && _vm.causaltiesDiff >= -4
                  ? _c("span", [
                      _vm._v(
                        "snížil o " + _vm._s(_vm.causaltiesDiff) + " oběti"
                      ),
                    ])
                  : _vm.causaltiesDiff >= 2 && _vm.causaltiesDiff <= 4
                  ? _c("span", [
                      _vm._v(
                        "zvýšil o " + _vm._s(_vm.causaltiesDiff) + " oběti"
                      ),
                    ])
                  : _vm.causaltiesDiff <= -5
                  ? _c("span", [
                      _vm._v(
                        "snížil o " + _vm._s(_vm.causaltiesDiff) + " obětí"
                      ),
                    ])
                  : _c("span", [
                      _vm._v(
                        "zvýšil o " + _vm._s(_vm.causaltiesDiff) + " obětí"
                      ),
                    ]),
                _vm._v(" oproti předešlému roku. "),
              ]),
              _vm.lastYear
                ? _c(
                    "div",
                    {
                      staticClass: "stats-result table-responsive",
                      staticStyle: { "overflow-x": "auto" },
                    },
                    [
                      _c(
                        "table",
                        {
                          staticClass:
                            "table table-hover table-sm table-bordered text-center",
                        },
                        [
                          _c("thead", [
                            _vm._m(1),
                            _c("tr", [
                              _c("th", [_vm._v(" ")]),
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v(_vm._s(_vm.lastYearValue)),
                              ]),
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v(_vm._s(_vm.thisYearValue)),
                              ]),
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v(_vm._s(_vm.lastYearValue)),
                              ]),
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v(_vm._s(_vm.thisYearValue)),
                              ]),
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v(_vm._s(_vm.lastYearValue)),
                              ]),
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v(_vm._s(_vm.thisYearValue)),
                              ]),
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v(_vm._s(_vm.lastYearValue)),
                              ]),
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v(_vm._s(_vm.thisYearValue)),
                              ]),
                            ]),
                          ]),
                          _c("tbody", [
                            _c("tr", [
                              _c("td", [_vm._v("Den")]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.lastYear.day.count)),
                              ]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.thisYear.day.count)),
                              ]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.lastYear.day.impact.deaths)),
                              ]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.thisYear.day.impact.deaths)),
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.lastYear.day.impact.severely)
                                ),
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.thisYear.day.impact.severely)
                                ),
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.lastYear.day.impact.slightly)
                                ),
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.thisYear.day.impact.slightly)
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("Měsíc")]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.lastYear.month.count)),
                              ]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.thisYear.month.count)),
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.lastYear.month.impact.deaths)
                                ),
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.thisYear.month.impact.deaths)
                                ),
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.lastYear.month.impact.severely)
                                ),
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.thisYear.month.impact.severely)
                                ),
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.lastYear.month.impact.slightly)
                                ),
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.thisYear.month.impact.slightly)
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("Rok")]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.lastYear.year.count)),
                              ]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.thisYear.year.count)),
                              ]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.lastYear.year.impact.deaths)),
                              ]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.thisYear.year.impact.deaths)),
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.lastYear.year.impact.severely)
                                ),
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.thisYear.year.impact.severely)
                                ),
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.lastYear.year.impact.slightly)
                                ),
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.thisYear.year.impact.slightly)
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _c("br"),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Kraj")]),
        _c("th", { attrs: { scope: "col" } }, [
          _c(
            "span",
            {
              staticClass: "category-container",
              attrs: { "aria-haspopup": "true", tabIndex: "0" },
            },
            [
              _c("span", { staticClass: "tooltip-box" }, [
                _c("span", { staticClass: "tiptext" }, [_vm._v("Počet nehod")]),
              ]),
              _c("img", {
                attrs: {
                  src: "/images/icons/crash.png",
                  width: "48",
                  height: "48",
                  alt: "Počet nehod",
                },
              }),
            ]
          ),
        ]),
        _c("th", { attrs: { scope: "col" } }, [
          _c(
            "span",
            {
              staticClass: "category-container",
              attrs: { "aria-haspopup": "true", tabIndex: "0" },
            },
            [
              _c("span", { staticClass: "tooltip-box" }, [
                _c("span", { staticClass: "tiptext" }, [_vm._v("Počet úmrtí")]),
              ]),
              _c("img", {
                attrs: {
                  src: "/images/icons/tombstone.png",
                  width: "48",
                  height: "48",
                  alt: "Počet úmrtí",
                },
              }),
            ]
          ),
        ]),
        _c("th", { attrs: { scope: "col" } }, [
          _c(
            "span",
            {
              staticClass: "category-container",
              attrs: { "aria-haspopup": "true", tabIndex: "0" },
            },
            [
              _c("span", { staticClass: "tooltip-box" }, [
                _c("span", { staticClass: "tiptext" }, [
                  _vm._v("Počet těžkých zranění"),
                ]),
              ]),
              _c("img", {
                attrs: {
                  src: "/images/icons/ambulance.png",
                  width: "48",
                  height: "48",
                  alt: "Počet těžkých zranění",
                },
              }),
            ]
          ),
        ]),
        _c("th", { attrs: { scope: "col" } }, [
          _c(
            "span",
            {
              staticClass: "category-container",
              attrs: { "aria-haspopup": "true", tabIndex: "0" },
            },
            [
              _c("span", { staticClass: "tooltip-box" }, [
                _c("span", { staticClass: "tiptext" }, [
                  _vm._v("Počet lehkých zranění"),
                ]),
              ]),
              _c("img", {
                attrs: {
                  src: "/images/icons/minor_injuries.png",
                  width: "48",
                  height: "48",
                  alt: "Počet lehkých zranění",
                },
              }),
            ]
          ),
        ]),
        _c("th", { attrs: { scope: "col" } }, [
          _c(
            "span",
            {
              staticClass: "category-container",
              attrs: { "aria-haspopup": "true", tabIndex: "0" },
            },
            [
              _c("span", { staticClass: "tooltip-box" }, [
                _c("span", { staticClass: "tiptext" }, [
                  _vm._v("Nepřiměřená rychlost"),
                ]),
              ]),
              _c("img", {
                attrs: {
                  src: "/images/icons/reason_speed.png",
                  width: "48",
                  height: "48",
                  alt: "Nepřiměřená rychlost",
                },
              }),
            ]
          ),
        ]),
        _c("th", { attrs: { scope: "col" } }, [
          _c(
            "span",
            {
              staticClass: "category-container",
              attrs: { "aria-haspopup": "true", tabIndex: "0" },
            },
            [
              _c("span", { staticClass: "tooltip-box" }, [
                _c("span", { staticClass: "tiptext" }, [
                  _vm._v("Nedání přednosti v jízdě"),
                ]),
              ]),
              _c("img", {
                attrs: {
                  src: "/images/icons/reason_give_way.png",
                  width: "48",
                  height: "48",
                  alt: "Nedání přednosti v jízdě",
                },
              }),
            ]
          ),
        ]),
        _c("th", { attrs: { scope: "col" } }, [
          _c(
            "span",
            {
              staticClass: "category-container",
              attrs: { "aria-haspopup": "true", tabIndex: "0" },
            },
            [
              _c("span", { staticClass: "tooltip-box" }, [
                _c("span", { staticClass: "tiptext" }, [
                  _vm._v("Nesprávné předjíždění"),
                ]),
              ]),
              _c("img", {
                attrs: {
                  src: "/images/icons/reason_passing.png",
                  width: "48",
                  height: "48",
                  alt: "Nesprávné předjíždění",
                },
              }),
            ]
          ),
        ]),
        _c("th", { attrs: { scope: "col" } }, [
          _c(
            "span",
            {
              staticClass: "category-container",
              attrs: { "aria-haspopup": "true", tabIndex: "0" },
            },
            [
              _c("span", { staticClass: "tooltip-box" }, [
                _c("span", { staticClass: "tiptext" }, [
                  _vm._v("Pod vlivem návykových látek"),
                ]),
              ]),
              _c("img", {
                attrs: {
                  src: "/images/icons/reason_drunk.png",
                  width: "48",
                  height: "48",
                  alt: "Pod vlivem návykových látek",
                },
              }),
            ]
          ),
        ]),
        _c("th", { attrs: { scope: "col" } }, [
          _c(
            "span",
            {
              staticClass: "category-container",
              attrs: { "aria-haspopup": "true", tabIndex: "0" },
            },
            [
              _c("span", { staticClass: "tooltip-box" }, [
                _c("span", { staticClass: "tiptext" }, [
                  _vm._v("Nesprávný způsob jízdy"),
                ]),
              ]),
              _c("img", {
                attrs: {
                  src: "/images/icons/reason_mistake.png",
                  width: "48",
                  height: "48",
                  alt: "Nesprávný způsob jízdy",
                },
              }),
            ]
          ),
        ]),
        _c("th", { attrs: { scope: "col" } }, [
          _c(
            "span",
            {
              staticClass: "category-container",
              attrs: { "aria-haspopup": "true", tabIndex: "0" },
            },
            [
              _c("span", { staticClass: "tooltip-box" }, [
                _c("span", { staticClass: "tiptext" }, [
                  _vm._v("Jiná příčina"),
                ]),
              ]),
              _c("img", {
                attrs: {
                  src: "/images/icons/reason_other.png",
                  width: "48",
                  height: "48",
                  alt: "Jiná příčina",
                },
              }),
            ]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v(" ")]),
      _c("th", { attrs: { colspan: "2", scope: "col" } }, [
        _c(
          "span",
          {
            staticClass: "category-container",
            attrs: { "aria-haspopup": "true", tabIndex: "0" },
          },
          [
            _c("span", { staticClass: "tooltip-box" }, [
              _c("span", { staticClass: "tiptext" }, [_vm._v("Počet nehod")]),
            ]),
            _c("img", {
              attrs: {
                src: "/images/icons/crash.png",
                width: "48",
                height: "48",
                alt: "Počet nehod",
              },
            }),
          ]
        ),
      ]),
      _c("th", { attrs: { colspan: "2", scope: "col" } }, [
        _c(
          "span",
          {
            staticClass: "category-container",
            attrs: { "aria-haspopup": "true", tabIndex: "0" },
          },
          [
            _c("span", { staticClass: "tooltip-box" }, [
              _c("span", { staticClass: "tiptext" }, [_vm._v("Počet úmrtí")]),
            ]),
            _c("img", {
              attrs: {
                src: "/images/icons/tombstone.png",
                width: "48",
                height: "48",
                alt: "Počet úmrtí",
              },
            }),
          ]
        ),
      ]),
      _c("th", { attrs: { colspan: "2", scope: "col" } }, [
        _c(
          "span",
          {
            staticClass: "category-container",
            attrs: { "aria-haspopup": "true", tabIndex: "0" },
          },
          [
            _c("span", { staticClass: "tooltip-box" }, [
              _c("span", { staticClass: "tiptext" }, [
                _vm._v("Počet těžkých zranění"),
              ]),
            ]),
            _c("img", {
              attrs: {
                src: "/images/icons/ambulance.png",
                width: "48",
                height: "48",
                alt: "Počet těžkých zranění",
              },
            }),
          ]
        ),
      ]),
      _c("th", { attrs: { colspan: "2", scope: "col" } }, [
        _c(
          "span",
          {
            staticClass: "category-container",
            attrs: { "aria-haspopup": "true", tabIndex: "0" },
          },
          [
            _c("span", { staticClass: "tooltip-box" }, [
              _c("span", { staticClass: "tiptext" }, [
                _vm._v("Počet lehkých zranění"),
              ]),
            ]),
            _c("img", {
              attrs: {
                src: "/images/icons/minor_injuries.png",
                width: "48",
                height: "48",
                alt: "Počet lehkých zranění",
              },
            }),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }