var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "share-links-wrap" }, [
    _c(
      "div",
      { staticClass: "share-box" },
      [
        _c("b-button", {
          staticClass: "p-0",
          attrs: { variant: "transparent" },
          domProps: { innerHTML: _vm._s(_vm.twitter) },
          on: {
            click: function ($event) {
              return _vm.shareLink("twitter")
            },
          },
        }),
        _c("b-button", {
          staticClass: "p-0",
          attrs: { variant: "transparent" },
          domProps: { innerHTML: _vm._s(_vm.facebook) },
          on: {
            click: function ($event) {
              return _vm.shareLink("facebook")
            },
          },
        }),
        _c("b-button", {
          staticClass: "p-0",
          attrs: { variant: "transparent" },
          domProps: { innerHTML: _vm._s(_vm.messenger) },
          on: {
            click: function ($event) {
              return _vm.shareLink("messenger")
            },
          },
        }),
        _c("b-button", {
          staticClass: "p-0",
          attrs: { variant: "transparent" },
          domProps: { innerHTML: _vm._s(_vm.whatsapp) },
          on: {
            click: function ($event) {
              return _vm.shareLink("whatsapp")
            },
          },
        }),
        _c("b-button", {
          staticClass: "p-0",
          attrs: { variant: "transparent" },
          domProps: { innerHTML: _vm._s(_vm.email) },
          on: {
            click: function ($event) {
              return _vm.shareLink("email")
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }