var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-2 chart-area" },
    [
      _c("column-chart", {
        attrs: {
          data: _vm.chartData,
          colors: ["#007bffff", "#28a745ff"],
          suffix: _vm.suffix,
          height: "300px",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }