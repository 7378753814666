var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "form",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var passes = ref.passes
          return [
            _c(
              "b-form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return passes(_vm.signIn)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "mt-5 border rounded centerbox" },
                  [
                    _c(
                      "b-row",
                      { staticClass: "w-85 m-auto pb-1" },
                      [
                        _c("b-col", [
                          _c("div", { staticClass: "head-area" }, [
                            _c("h2", [
                              _vm._v(_vm._s(_vm.$t("sign-in.sign-in-heading"))),
                            ]),
                            _c("div", [_vm._v(_vm._s(_vm.message))]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "field-area" },
                            [
                              _c("TextInput", {
                                staticClass: "w-100",
                                attrs: {
                                  rules: "required|email",
                                  name: "email",
                                  type: "email",
                                  label: _vm.$t("profile.email"),
                                  placeholder: _vm.$t(
                                    "generic.email-placeholder"
                                  ),
                                },
                                model: {
                                  value: _vm.email,
                                  callback: function ($$v) {
                                    _vm.email = $$v
                                  },
                                  expression: "email",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "field-area" },
                            [
                              _c("TextInput", {
                                staticClass: "w-100",
                                attrs: {
                                  rules: "required",
                                  label: _vm.$t("profile.password"),
                                  placeholder: _vm.$t(
                                    "sign-in.password-placeholder"
                                  ),
                                  name: "password",
                                  type: "password",
                                },
                                model: {
                                  value: _vm.password,
                                  callback: function ($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-100 d-flex flex-row-reverse mb-3 forgot-link",
                            },
                            [
                              _c(
                                "span",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: { name: "resend-activation" },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("sign-up.resend-link")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                { staticClass: "mr-1" },
                                [
                                  _c(
                                    "router-link",
                                    { attrs: { to: { name: "forgotten" } } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "sign-in.forgot-password-link"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm.error
                            ? _c("div", { staticClass: "text-danger" }, [
                                _c("strong", [
                                  _vm._v(" " + _vm._s(_vm.error) + " "),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      { staticClass: "w-85 m-auto pb-2" },
                      [
                        _c(
                          "b-col",
                          [
                            _c("Button", {
                              staticClass: "w-100 btn btn-block blue",
                              attrs: {
                                disabled: _vm.invalid,
                                value: _vm.$t("sign-in.sign-in-button"),
                              },
                              on: { clicked: _vm.signIn },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          [
                            _c("Button", {
                              staticClass: "btn btn-block green",
                              attrs: {
                                value: _vm.$t("sign-in.sign-up-button"),
                                id: "signin__sign-up-button",
                              },
                              on: { clicked: _vm.redirectToSignUp },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      { staticClass: "w-85 m-auto pb-2" },
                      [
                        _c("b-col", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-center text-uppercase",
                            },
                            [_vm._v(_vm._s(_vm.$t("sign-in.or")))]
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      { staticClass: "w-85 m-auto pb-2" },
                      [
                        _c(
                          "b-col",
                          [
                            _c("Button", {
                              staticClass: "w-100 btn btn-block btn-facebook",
                              attrs: {
                                value: _vm.$t("sign-in.sign-in-facebook"),
                              },
                              on: {
                                clicked: function ($event) {
                                  return _vm.auth("facebook")
                                },
                              },
                            }),
                            _c("Button", {
                              staticClass: "w-100 btn btn-block btn-twitter",
                              attrs: {
                                value: _vm.$t("sign-in.sign-in-twitter"),
                              },
                              on: {
                                clicked: function ($event) {
                                  return _vm.auth("twitter")
                                },
                              },
                            }),
                            _c("Button", {
                              staticClass:
                                "w-100 btn btn-block btn-google-plus",
                              attrs: {
                                value: _vm.$t("sign-in.sign-in-google"),
                              },
                              on: {
                                clicked: function ($event) {
                                  return _vm.auth("google")
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }