var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-5 border centerbox" },
    [
      _c("div", { staticClass: "head-area" }, [
        _c("h2", [_vm._v(_vm._s(_vm.$t("sign-up.heading")))]),
        !_vm.succeeded
          ? _c("p", [_vm._v(_vm._s(_vm.$t("sign-up.body")))])
          : _vm._e(),
      ]),
      _c("ValidationObserver", {
        ref: "form",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var passes = ref.passes
              var invalid = ref.invalid
              return [
                !_vm.succeeded
                  ? _c(
                      "b-form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return passes(_vm.submitForm)
                          },
                        },
                      },
                      [
                        _c(
                          "fieldset",
                          { attrs: { disabled: _vm.wholeDisable } },
                          [
                            _c(
                              "div",
                              { staticClass: "field-area" },
                              [
                                _c("TextInput", {
                                  attrs: {
                                    rules: "required|email|conflict:email",
                                    label: _vm.$t("profile.email"),
                                    placeholder: _vm.$t("sign-up.email-hint"),
                                    name: "email",
                                    disabled: _vm.socialId !== undefined,
                                    type: "email",
                                  },
                                  model: {
                                    value: _vm.email,
                                    callback: function ($$v) {
                                      _vm.email = $$v
                                    },
                                    expression: "email",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "field-area" },
                              [
                                !_vm.socialId
                                  ? _c("TextInput", {
                                      attrs: {
                                        rules: "required|min:6",
                                        label: _vm.$t("profile.password"),
                                        placeholder: _vm.$t(
                                          "sign-up.password-hint"
                                        ),
                                        name: "password",
                                        type: "password",
                                      },
                                      model: {
                                        value: _vm.password,
                                        callback: function ($$v) {
                                          _vm.password = $$v
                                        },
                                        expression: "password",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "field-area" },
                              [
                                _c("TextInput", {
                                  attrs: {
                                    rules: "required|min:3|conflict:nick",
                                    label: _vm.$t("profile.nickname"),
                                    placeholder: _vm.$t(
                                      "sign-up.nickname-hint"
                                    ),
                                    name: "nickname",
                                  },
                                  model: {
                                    value: _vm.nickname,
                                    callback: function ($$v) {
                                      _vm.nickname = $$v
                                    },
                                    expression: "nickname",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "field-area" },
                              [
                                _c("Checkbox", {
                                  attrs: {
                                    label: _vm.$t("sign-up.personal-data"),
                                    name: "personal-data",
                                    identifier: "personalData",
                                  },
                                  model: {
                                    value: _vm.personalData,
                                    callback: function ($$v) {
                                      _vm.personalData = $$v
                                    },
                                    expression: "personalData",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "prof-form-wrap" },
                              [
                                _vm.personalData
                                  ? _c("ProfileForm", {
                                      attrs: { formData: _vm.profileForm },
                                      on: { update: _vm.updateProfileForm },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "field-area" }, [
                              _c("h5", [
                                _vm._v(_vm._s(_vm.$t("sign-up.consents"))),
                              ]),
                              _c(
                                "div",
                                [
                                  _c(
                                    "Checkbox",
                                    {
                                      attrs: {
                                        rules: {
                                          required: { allowFalse: false },
                                        },
                                        name: "terms",
                                        identifier: "termsAndConditions",
                                      },
                                      model: {
                                        value: _vm.termsAndConditions,
                                        callback: function ($$v) {
                                          _vm.termsAndConditions = $$v
                                        },
                                        expression: "termsAndConditions",
                                      },
                                    },
                                    [
                                      _c(
                                        "i18n",
                                        {
                                          attrs: {
                                            path: "profile.terms",
                                            tag: "label",
                                            for: "terms",
                                          },
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "/o/podminky",
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("profile.terms-link")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "Checkbox",
                                    {
                                      attrs: {
                                        rules: {
                                          required: { allowFalse: false },
                                        },
                                        name: "privacy",
                                        identifier: "personalDataProcessing",
                                      },
                                      model: {
                                        value: _vm.personalDataProcessing,
                                        callback: function ($$v) {
                                          _vm.personalDataProcessing = $$v
                                        },
                                        expression: "personalDataProcessing",
                                      },
                                    },
                                    [
                                      _c(
                                        "i18n",
                                        {
                                          attrs: {
                                            path: "profile.privacy",
                                            tag: "label",
                                            for: "personal",
                                          },
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "/o/soukromi",
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("profile.privacy-link")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _vm.error
                              ? _c("div", { staticClass: "text-danger" }, [
                                  _c("strong", [
                                    _vm._v(" " + _vm._s(_vm.error) + " "),
                                  ]),
                                ])
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "col-sm-12 col-md-4 m-auto" },
                              [
                                _c("Button", {
                                  staticClass: "w-100",
                                  attrs: {
                                    waiting: _vm.sending,
                                    disabled: invalid,
                                    value: _vm.$t(
                                      "sign-up.finished-button-label"
                                    ),
                                  },
                                  on: {
                                    clicked: function ($event) {
                                      return _vm.submitForm()
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _vm.success === true
        ? _c("div", { staticClass: "success-msg" }, [
            _vm._v(" " + _vm._s(_vm.$t("sign-up.success-message")) + " "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }