var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-3 centerbox" },
    [
      _vm.item ? _c("h1", [_vm._v(_vm._s(_vm.item.info.caption))]) : _vm._e(),
      _c("ValidationObserver", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var invalid = ref.invalid
              return [
                _c("b-form-textarea", {
                  staticClass: "mb-3",
                  attrs: {
                    id: "textarea",
                    rows: "10",
                    "min-rows": "3",
                    "aria-describedby": "content-errors",
                    rules: { required: true },
                  },
                  model: {
                    value: _vm.html,
                    callback: function ($$v) {
                      _vm.html = $$v
                    },
                    expression: "html",
                  },
                }),
                _c(
                  "b-form-invalid-feedback",
                  {
                    attrs: {
                      id: "content-errors",
                      state: _vm.hideContentError,
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("blog.form.content-error")))]
                ),
                _c(
                  "b-button",
                  {
                    attrs: {
                      variant: "post-btn",
                      disabled: invalid || _vm.isEmpty || !_vm.isEditor,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.saveArticle()
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("generic.save-button")) + " ")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }