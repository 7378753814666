var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pass-change" },
    [
      _c("ValidationObserver", {
        ref: "form",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var passes = ref.passes
              var invalid = ref.invalid
              return [
                _c(
                  "b-form",
                  {
                    attrs: { action: "placeholder", method: "post" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return passes(_vm.changePassword)
                      },
                    },
                  },
                  [
                    _c("h3", [
                      _vm._v(_vm._s(_vm.$t("sign-in.change-password-heading"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "field-area" },
                      [
                        _c("TextInput", {
                          attrs: {
                            value: _vm.email,
                            label: _vm.$t("profile.email"),
                            name: "email",
                            type: "email",
                            disabled: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "field-area" },
                      [
                        _c("TextInput", {
                          attrs: {
                            rules: "required",
                            label: _vm.$t("sign-in.current-password"),
                            name: "current-password",
                            type: "password",
                          },
                          model: {
                            value: _vm.currentPassword,
                            callback: function ($$v) {
                              _vm.currentPassword = $$v
                            },
                            expression: "currentPassword",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "field-area" },
                      [
                        _c("TextInput", {
                          attrs: {
                            rules: "required|min:6",
                            label: _vm.$t("sign-in.new-password"),
                            name: "new-password",
                            type: "password",
                            autocomplete: "new-password",
                          },
                          model: {
                            value: _vm.newPassword,
                            callback: function ($$v) {
                              _vm.newPassword = $$v
                            },
                            expression: "newPassword",
                          },
                        }),
                        _vm.error
                          ? _c("div", [
                              _c("strong", { staticClass: "text-error" }, [
                                _vm._v(" " + _vm._s(_vm.error) + " "),
                              ]),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("Button", {
                      staticClass: "mt-1",
                      attrs: {
                        disabled: invalid,
                        value: _vm.$t("sign-in.change-password-button"),
                      },
                      on: { clicked: _vm.changePassword },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }