var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "selectors" },
        [
          _c("Radio", {
            staticClass: "comment-box mr-3",
            attrs: {
              label: _vm.$t("profile.filter.comments"),
              name: "filter",
              identifier: "comment",
            },
            model: {
              value: _vm.filter,
              callback: function ($$v) {
                _vm.filter = $$v
              },
              expression: "filter",
            },
          }),
          _c("Radio", {
            staticClass: "post-box mr-3",
            attrs: {
              label: _vm.$t("profile.filter.posts"),
              name: "filter",
              identifier: "blog",
            },
            model: {
              value: _vm.filter,
              callback: function ($$v) {
                _vm.filter = $$v
              },
              expression: "filter",
            },
          }),
          _c("Radio", {
            staticClass: "post-box",
            attrs: {
              label: _vm.$t("profile.filter.articles"),
              name: "filter",
              identifier: "article",
            },
            model: {
              value: _vm.filter,
              callback: function ($$v) {
                _vm.filter = $$v
              },
              expression: "filter",
            },
          }),
        ],
        1
      ),
      _c(
        "ul",
        { staticClass: "post-list" },
        _vm._l(_vm.list, function (item) {
          return _c(
            "li",
            { key: item._id },
            [
              _c(
                "span",
                [
                  _c("BIconClock", { attrs: { "font-scale": "1" } }),
                  _c("Date", {
                    attrs: { date: item.date, format: "dynamicDateTime" },
                  }),
                ],
                1
              ),
              _c("router-link", { attrs: { to: _vm.getURL(item) } }, [
                _vm._v(_vm._s(item.text)),
              ]),
            ],
            1
          )
        }),
        0
      ),
      !_vm.hasEnded
        ? _c("Button", {
            staticClass: "mt-3 loadmore",
            attrs: { size: "sm", value: _vm.$t("generic.loading-more-button") },
            on: { clicked: _vm.fetchActivity },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }