var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "header",
        [
          _c(
            "b-navbar",
            { attrs: { toggleable: "sm", type: "dark", variant: "dark" } },
            [
              _c(
                "b-nav-item",
                { staticClass: "brand-pic", attrs: { href: "/" } },
                [
                  _c("img", {
                    staticClass: "d-inline-block align-top brand",
                    attrs: {
                      alt: _vm.$t("app.logo-alt"),
                      src: "/images/icons/logo.png",
                    },
                  }),
                ]
              ),
              _c(
                "b-navbar-nav",
                { staticClass: "d-none d-sm-block d-sm-dark" },
                [
                  _c(
                    "div",
                    { staticClass: "d-inline-flex flex-column title-tag" },
                    [
                      _c("h2", { staticClass: "app-title" }, [
                        _c("a", { attrs: { href: "/" } }, [
                          _vm._v("MEZI NÁMI "),
                          _c("span", [_vm._v("ŘIDIČI")]),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
              _c(
                "b-navbar-nav",
                { staticClass: "ml-auto align-items-center rightmenu info" },
                [
                  !_vm.authorized
                    ? _c(
                        "b-nav-item",
                        [
                          _c(
                            "router-link",
                            { attrs: { to: { name: "sign-in" } } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("app.sign-in-up")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.authorized
                    ? _c(
                        "b-nav-item",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          staticClass: "edit",
                          attrs: {
                            title: _vm.$t("app.new-post"),
                            right: "",
                            "toggle-class": "text-warning ",
                          },
                        },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "create-blog",
                                  params: { id: _vm.userId },
                                },
                              },
                            },
                            [
                              _c("BIconPencilSquare", {
                                attrs: { scale: "2" },
                              }),
                              _vm._v(
                                " " + _vm._s(_vm.$t("app.new-post")) + " "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.authorized
                    ? _c(
                        "b-nav-item-dropdown",
                        {
                          staticClass: "sign-out",
                          attrs: { right: "", "toggle-class": "text-warning " },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function () {
                                  return [
                                    _c("BIconPersonCircle", {
                                      attrs: { scale: "2" },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3277752370
                          ),
                        },
                        [
                          _c(
                            "b-dropdown-item",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item p-0",
                                  attrs: {
                                    to: {
                                      name: "user-profile",
                                      params: { id: _vm.userId },
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("app.my-profile")) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-dropdown-item",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item p-0",
                                  attrs: {
                                    to: {
                                      name: "update-profile",
                                      params: { id: _vm.userId },
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("app.update-profile")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.canWriteArticles
                            ? _c(
                                "b-dropdown-item",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "dropdown-item p-0",
                                      attrs: { to: { name: "articles" } },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("page-title.articles")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-dropdown-item",
                            {
                              staticClass: "sign-out-account",
                              attrs: { href: "#0" },
                              on: {
                                click: function ($event) {
                                  return _vm.signMeOut()
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("app.sign-out")) + " ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("info-box"),
          _vm.updateExists
            ? _c(
                "b-button",
                {
                  staticClass:
                    "w-100 fixed-top bg-warning text-dark rounded-0 border-warning",
                  on: { click: _vm.refreshApp },
                },
                [_vm._v(" " + _vm._s(_vm.$t("app.update")) + " ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("main", [_c("router-view")], 1),
      _c(
        "footer",
        { staticClass: "py-3 bg-footer" },
        [
          _c("b-container", { staticClass: "app-size" }, [
            _c(
              "div",
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: { to: "/o/mise", "active-class": "active" },
                          },
                          [_vm._v(_vm._s(_vm.$t("app.our-mission")))]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      [
                        _c("router-link", { attrs: { to: "/o/napoveda" } }, [
                          _vm._v(_vm._s(_vm.$t("app.help"))),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      [
                        _c("router-link", { attrs: { to: "/o/kontakt" } }, [
                          _vm._v(_vm._s(_vm.$t("app.contact"))),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      [
                        _c("router-link", { attrs: { to: "/o/reklama" } }, [
                          _vm._v(_vm._s(_vm.$t("app.advertisement"))),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      [
                        _c("router-link", { attrs: { to: "/o/podminky" } }, [
                          _vm._v(_vm._s(_vm.$t("app.terms"))),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      [
                        _c("router-link", { attrs: { to: "/o/soukromi" } }, [
                          _vm._v(_vm._s(_vm.$t("app.privacy"))),
                        ]),
                      ],
                      1
                    ),
                    _c("b-col", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.manageCookies()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("app.cookies")))]
                      ),
                    ]),
                    _c("b-col", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://www.facebook.com/mezinamiridici",
                          },
                        },
                        [
                          _c("BIconFacebook", {
                            attrs: { scale: "1.3", variant: "warning" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "ml-5",
                          attrs: { href: "https://twitter.com/MeziNamiRidici" },
                        },
                        [
                          _c("BIconTwitter", {
                            attrs: { scale: "1.3", variant: "warning" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "float-right",
                          attrs: { href: "/feed.rss" },
                        },
                        [
                          _c("BIconRssFill", {
                            attrs: { scale: "1.3", variant: "warning" },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c("hr", { staticClass: "footer-line" }),
                _c(
                  "div",
                  { staticClass: "text-center text-white opacity-25" },
                  [
                    _vm._v(
                      " Copyright © 2021 - " +
                        _vm._s(new Date().getFullYear()) +
                        " Lelisoft s.r.o "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("CookiesBox", {
        on: {
          cookiePreferenceChange: function ($event) {
            return _vm.handleCookies($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }